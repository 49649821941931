import { Component, input, output } from '@angular/core';
import { ButtonComponent } from '@ui/button/button.component';

@Component({
	selector: 'csd-app-actions',
	standalone: true,
	imports: [ButtonComponent],
	templateUrl: './actions.component.html',
	styleUrl: './actions.component.scss',
})
export class ActionsComponent {
	cancelLabel = input.required<string>();
	continueLabel = input.required<string>();
	continueDisabled = input<boolean>(false);

	submitted = output<void>();
	canceled = output<void>();

	cancel() {
		this.canceled.emit();
	}

	continue() {
		this.submitted.emit();
	}
}
