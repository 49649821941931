<div class="pt-12 w-full px4 md:px-12">
	<div class="flex justify-between p-2 text-teal-800">
		<a class="after:content-['_↗']" href="https://docs.emypeople.net" target="_blank">
			Need help?
		</a>
		<ng-content select="[extra-info]"></ng-content>
	</div>
	<div class="flex flex-col-reverse justify-between md:flex-row">
		<csd-app-button
			classList="mt-7 md:mt-0 md:w-32"
			type="outlined"
			variation="secondary"
			(click)="cancel()"
		>
			<span [innerHTML]="cancelLabel()"></span>
		</csd-app-button>
		<csd-app-button
			classList="mt-auto md:w-52"
			variation="secondary"
			[disabled]="continueDisabled()"
			(click)="continue()"
		>
			{{ continueLabel() }}
		</csd-app-button>
	</div>
</div>
