<div [formGroup]="form" class="transition-all duration-1000">
	@if (isEditing()) {
		<input
			type="text"
			matInput
			formControlName="uninstallKey"
			class="password-input"
			(keypress)="onKey($event)"
		/>
		<div class="text-right">
			<button (click)="cancel()" class="password-action-button password-action-button--cancel mr-1">
				<mat-icon inline class="material-symbols-filled align-top">close</mat-icon>
			</button>
			<button
				(click)="confirmChange()"
				class="password-action-button password-action-button--confirm"
			>
				<mat-icon inline class="material-symbols-filled align-top">check</mat-icon>
			</button>
		</div>
	} @else {
		<span class="inline-block max-w-32 overflow-hidden mr-1">
			{{ toggleText(form.value.uninstallKey) }}
		</span>
		<button (click)="isEditing.set(true)">
			<mat-icon inline class="material-symbols-filled">edit</mat-icon>
		</button>
	}
</div>
