import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnInit,
	ViewChild,
} from '@angular/core';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { UserService } from '@feature/user/user.service';
import { ButtonComponent } from '@ui/button/button.component';
import { UserImageComponent } from '@ui/user-image/user-image.component';

import { UserStore } from '../user/user.store';
import { CompanyGroup } from '../user/user.types';

@Component({
	selector: 'csd-app-user-area',
	standalone: true,
	templateUrl: './user-area.component.html',
	styleUrl: './user-area.component.scss',
	imports: [MatExpansionModule, ButtonComponent, UserImageComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAreaComponent implements OnInit, AfterViewInit {
	@ViewChild(MatExpansionPanel) expansionPanel!: MatExpansionPanel;

	readonly #store = inject(UserStore);
	#authService = inject(AuthService);
	#router = inject(Router);
	#userService = inject(UserService);

	groupList = computed(() => {
		return this.#userService.userGroups().filter((group) => group.dashboard);
	});
	userName = computed(() => this.#store.user()?.name ?? '');

	ngOnInit(): void {
		this.#userService
			.getListContent(this.#store.user()?.sub as string)
			.subscribe(this.updateListContent.bind(this));
	}

	ngAfterViewInit(): void {
		this.expansionPanel._body.nativeElement.classList.add('user-area__content');
	}

	getGroupName(group: CompanyGroup) {
		return group.groupName || group.access;
	}

	isActiveGroup(groupId: string, isFirst: boolean) {
		const selectedGroup = this.#store.selectedGroup();

		return selectedGroup ? selectedGroup === groupId : isFirst;
	}

	logout() {
		this.#authService.logout().subscribe(() => {
			this.#router.navigate(['authentication', 'login']);
		});
	}

	updateListContent(data: any) {
		this.#store.updateSelectedGroup(data?.[0]?.access);
	}

	updateSelectedGroup(groupId: string) {
		this.#store.updateSelectedGroup(groupId);
	}
}
