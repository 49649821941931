import { Component, computed, inject } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CustomersStore } from '@feature/customers/customers.store';
import { UserAreaComponent } from '@feature/user-area/user-area.component';
import { UserService } from '@feature/user/user.service';
import { UserStore } from '@feature/user/user.store';
import { ButtonComponent } from '@ui/button/button.component';

import { CONFIG_BUTTONS, CUSTOMER_BUTTONS } from './sidebar.constants';

@Component({
	selector: 'csd-app-sidebar',
	standalone: true,
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.scss',
	imports: [ButtonComponent, RouterLink, RouterLinkActive, UserAreaComponent],
})
export class SidebarComponent {
	readonly #customerStore = inject(CustomersStore);
	readonly #userStore = inject(UserStore);
	#router = inject(Router);
	#userService = inject(UserService);

	customer = this.#customerStore.selectedCustomer;
	customerButtons = CUSTOMER_BUTTONS;
	shouldDisplayDashboard = this.#userService.shouldDisplayDashboard;

	configButtons = computed(() => {
		return CONFIG_BUTTONS.filter((buttonData) => {
			if (!buttonData.restriction) {
				return true;
			}

			return this.#userStore
				.user()
				.groups.some((group) => buttonData.restriction?.includes(group.toLowerCase()));
		}).map((buttonData) => ({
			...buttonData,
			path: buttonData.path.replace('{customer}/', `${this.customer()?.code || 'public'}/`),
		}));
	});

	getPath(route: string) {
		return [this.customer()?.code, route];
	}

	validateActiveRoute(route: string): boolean {
		const routeRegex = new RegExp(`${route}`, 'gi');

		return !!this.#router.url.match(routeRegex);
	}
}
