import { Component, computed, inject, signal } from '@angular/core';

import { CustomersStore } from '../customers/customers.store';
import { CustomerInfoComponent } from './customer-info/customer-info.component';
import { CustomerSearchComponent } from './customer-search/customer-search.component';

@Component({
	selector: 'csd-app-customer-selection',
	standalone: true,
	templateUrl: './customer-selection.component.html',
	styleUrl: './customer-selection.component.scss',
	imports: [CustomerInfoComponent, CustomerSearchComponent],
})
export class CustomerSelectionComponent {
	readonly #store = inject(CustomersStore);
	searchTouched = signal<boolean>(false);
	shouldOpenSearch = signal<boolean>(true);
	shouldOpenSidenav = signal<boolean>(true);

	isSearchOpen = computed(() => {
		const isCustomerActive = !!this.#store.selectedCustomer()?.code;
		const searchOpen = this.shouldOpenSearch();

		if (!isCustomerActive) {
			return true;
		}

		if (!this.searchTouched()) {
			return false;
		}

		return !isCustomerActive || searchOpen;
	});

	searchFocusOut(searchValue: string) {
		if (!searchValue) {
			this.shouldOpenSearch.set(false);
		}
	}
}
