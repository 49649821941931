<mat-sidenav-container>
	<mat-sidenav
		[mode]="isMobile() ? 'over' : 'side'"
		[opened]="shouldOpenSidenav()"
		fixedInViewport
		(openedChange)="onChangeSidenav($event)"
	>
		<csd-app-sidebar></csd-app-sidebar>
	</mat-sidenav>
	<mat-sidenav-content class="min-h-screen bg-white">
		@if (shouldDisplayDashboard()) {
			<div class="content-mobile">
				<div class="content-mobile__menu">
					<button class="rounded-full p-4 flex items-center" matRipple (click)="openSideNav()">
						<mat-icon class="align-sub">menu</mat-icon>
					</button>
				</div>
				<div class="content-mobile__logo">
					<img class="h-10" src="../../../../assets/logo-emypeople.png" alt="eMyPeople logo" />
					<p class="tex-sm font-bold leading-none pt-2 pl-2">Dashboard</p>
				</div>
			</div>
			<div class="pl-5 py-2 pr-4 w-full">
				<div class="flex items-center w-full pb-10 min-h-28 flex-col sm:flex-row">
					<div class="w-full pr-5">
						<csd-app-customer-selection />
					</div>
					<div class="flex items-center">
						<csd-app-history />
					</div>
				</div>
				<div class="pb-8">
					<csd-app-breadcrumb />
				</div>
				<csd-app-loader [isLoading]="isLoadingCustomer()">
					<router-outlet></router-outlet>
				</csd-app-loader>
			</div>
		} @else {
			<section class="flex items-center justify-center h-screen">
				<csd-app-loader [isLoading]="isLoadingUserGroups()">
					<div class="max-w-xl px-5 md:px-0 pb-20">
						<csd-app-empty-state icon="report">
							No Dashboard Access
							<ng-content empty-state-message>
								You currently don't have permission to view dashboards. To gain access, please
								contact your administrator or request additional permissions.
							</ng-content>
						</csd-app-empty-state>
					</div>
				</csd-app-loader>
			</section>
		}
	</mat-sidenav-content>
</mat-sidenav-container>
