<section
	class="section-container"
	[class.section-container--collapsed]="collapsible() && isCollapsed()"
>
	<header class="flex justify-between items-center text-base">
		<h3 class="font-bold pt-5 pb-3">{{ header() }}</h3>
		<div class="pt-2">
			<ng-content select="[header-actions]"></ng-content>
		</div>
	</header>

	<div class="content" [@collapse]="collapsible() && isCollapsed() ? 'closed' : 'open'">
		<ng-content></ng-content>
	</div>

	@if (collapsible()) {
		<div class="collapse-button-cont" (click)="toggleCollapse()">
			<button class="collapse-button">
				<mat-icon class="align-middle">arrow_drop_up</mat-icon>
			</button>
		</div>
	}
</section>
