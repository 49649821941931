import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';
import { AppCheckboxComponent } from '@ui/app-checkbox/app-checkbox.component';

@Component({
	selector: 'csd-app-restrictions',
	standalone: true,
	imports: [AppCheckboxComponent],
	templateUrl: './restrictions.component.html',
	styleUrl: './restrictions.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestrictionsComponent {
	rootFormGroup = inject(FormGroupDirective);

	get form() {
		return this.rootFormGroup.control;
	}

	get restrictionsControl() {
		return this.form.get('restrictions') as FormControl;
	}

	get restrictionsList() {
		return this.restrictionsControl.value || [];
	}

	changeSelection(event: boolean, index: number) {
		if (index >= this.restrictionsList?.length) {
			return;
		}

		const restrictionsList = [...this.restrictionsList];
		const restrictionToUpdate = { ...restrictionsList[index] };

		restrictionToUpdate.value = event;
		restrictionsList[index] = restrictionToUpdate;
		this.restrictionsControl.setValue(restrictionsList);
	}
}
