import {
	Component,
	effect,
	EffectRef,
	ElementRef,
	inject,
	input,
	OnDestroy,
	OnInit,
	output,
	signal,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UserStore } from '@feature/user/user.store';
import { NotificationService } from '@ui/notification/notification.service';
import { NotificationVariation } from '@ui/notification/notification.types';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { catchError, of, Subscription } from 'rxjs';

import { ActionsComponent } from '../actions/actions.component';
import { ConnectService } from '../connect.service';
import { OptionSelection } from '../options/options.component';
import { CODE_LENGTH, COUNTDOWN_IN_SECONDS } from './verify-code.constants';

@Component({
	selector: 'csd-app-verify-code',
	standalone: true,
	templateUrl: './verify-code.component.html',
	styleUrl: './verify-code.component.scss',
	imports: [ActionsComponent, CountdownComponent, ReactiveFormsModule],
})
export class VerifyCodeComponent implements OnInit, OnDestroy {
	readonly #store = inject(UserStore);
	#connectService = inject(ConnectService);
	#elementRef = inject(ElementRef);
	#fb = inject(FormBuilder);
	#notificationService = inject(NotificationService);
	#router = inject(Router);

	email = input.required<string>();
	validateOption = input.required<OptionSelection>();
	canceled = output<void>();

	codes = new Array(CODE_LENGTH).fill('').map((_, index) => index);
	countdownConfig = { leftTime: COUNTDOWN_IN_SECONDS, format: 'mm:ss' };
	form: FormGroup = this.#fb.group({
		code: this.#fb.array([]),
	});
	subscriptions: Subscription[] = [];

	canRequestNewCode = signal<boolean>(false);
	isLoading = signal<boolean>(false);

	effectRef!: EffectRef;

	constructor() {
		this.codes.forEach(() => this.codeControl.push(this.#fb.control('')));

		this.effectRef = effect(() => {
			if (!this.isLoading) {
				this.resetCode();
			}
		});
	}

	get codeControl() {
		return this.form.controls['code'] as FormArray;
	}

	get codeValue() {
		return this.codeControl.value.join('');
	}

	ngOnInit(): void {
		this.requestCode();

		this.form.valueChanges.subscribe((newValue) => {
			const formValue = newValue.code.join('');

			if (formValue.length === CODE_LENGTH) {
				this.submitCode();
			}
		});
	}

	ngOnDestroy(): void {
		this.effectRef.destroy();
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	countdownFinished(event: CountdownEvent) {
		if (event.action !== 'done') {
			return;
		}

		this.canRequestNewCode.set(true);
	}

	handleError(message: string) {
		return catchError(() => {
			this.#notificationService.openError({ message: message });

			return of([message]);
		});
	}

	codeKeyup(event: KeyboardEvent) {
		const target = event.target as HTMLInputElement;

		this.#notificationService.close();

		if (event.key === 'Backspace') {
			(target.previousElementSibling as HTMLInputElement)?.focus();
			return;
		}

		(target.nextElementSibling as HTMLInputElement)?.focus();
	}

	requestCode() {
		this.isLoading.set(true);
		const codeSub$ = this.#connectService
			.requestSecurityCode(this.email(), this.validateOption())
			.pipe(this.handleError('Error requesting code'))
			.subscribe({ complete: () => this.isLoading.set(false) });

		this.subscriptions.push(codeSub$);
	}

	resetCode() {
		this.codeControl.reset(this.codes.map(() => ''));
		this.#elementRef.nativeElement.querySelectorAll('input')[0].focus();
		this.canRequestNewCode.set(false);
	}

	stepBack() {
		this.resetCode();
		this.canceled.emit();
	}

	submitCode() {
		const { email, sub } = this.#store.user();
		const params = { sub, email, code: this.codeValue };

		const codeSub$ = this.#connectService
			.validateSecurityCode(params)
			.pipe(this.handleError('Error submitting code'))
			.subscribe({
				next: this.validateSubmissionResp.bind(this),
			});

		this.subscriptions.push(codeSub$);
	}

	validateSubmissionResp(resp: any) {
		if (!resp.verified) {
			this.#notificationService.open({
				variation: NotificationVariation.WARNING,
				message: 'Incorrect code, try again',
			});

			this.resetCode();
			return;
		}

		this.#router.navigate(['/']);
	}
}
