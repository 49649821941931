import { Routes } from '@angular/router';

import { AppsComponent } from './apps/apps.component';
import { GeneralComponent } from './general/general.component';
import { RestrictionsComponent } from './restrictions/restrictions.component';

const baseTitle = '| Policies | eMyPeople';

export default <Routes>[
	{
		path: '',
		redirectTo: 'general',
		pathMatch: 'prefix',
	},
	{
		path: 'general',
		component: GeneralComponent,
		title: `General ${baseTitle}`,
		data: { breadcrumb: 'General' },
	},
	{
		path: 'apps',
		component: AppsComponent,
		title: `Apps ${baseTitle}`,
		data: { breadcrumb: 'Apps' },
	},
	{
		path: 'restrictions',
		component: RestrictionsComponent,
		title: `Restrictions ${baseTitle}`,
		data: { breadcrumb: 'Restrictions' },
	},
];
