<div class="page-wrapper bg-white md:bg-slate-200">
  <div class="flex h-full w-full flex-col items-center justify-around md:m-3 lg:m-12">
    <div class="content-container h-full w-full md:h-auto md:max-w-2xl md:justify-center md:rounded-3xl md:shadow-2xl xl:max-w-4xl 2xl:max-w-7xl">
      <img class="relative mt-7 h-16 lg:h-22 lg:mt-8 2xl:mt-10 2xl:h-28" src="../../../../assets/logo-emypeople.png" alt="eMyPeople logo">
      <section id="content" class="w-full max-w-3xl pt-14 2xl:pt-20 pb-12 2xl:pb-32 px-4">
        <router-outlet></router-outlet>
      </section>
    </div>
  </div>
</div>
