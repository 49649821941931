import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { AppsListComponent } from '@ui/apps-list/apps-list.component';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';

@Component({
	selector: 'csd-app-apps',
	standalone: true,
	imports: [AppsListComponent, ButtonComponent, LoadingIconComponent, ReactiveFormsModule],
	templateUrl: './apps.component.html',
	styleUrl: './apps.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsComponent {
	rootFormGroup = inject(FormGroupDirective);

	get form() {
		return this.rootFormGroup.control;
	}
}
