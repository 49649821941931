import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { catchError, finalize, map, tap } from 'rxjs';

import {
	Group,
	GroupAppsUpdate,
	GroupGeneralUpdate,
	GroupRestrictionsUpdate,
	GroupServerResponse,
	PolicyOwner,
} from './policy-details.types';
import { mapGroupResponse } from './utils/map-group-response';

@Injectable({
	providedIn: 'root',
})
export class PolicyDetailsService {
	apiService = inject(ApiService);

	isLoadingDetails = signal<boolean>(false);
	currentGroup = signal<Group>({} as Group);
	ownerOptions = signal<PolicyOwner[]>([]);

	getGroup(groupId: string) {
		this.isLoadingDetails.set(true);

		return this.apiService.get<GroupServerResponse>(`/android/groups/${groupId}`).pipe(
			map(mapGroupResponse),
			tap((data) => this.currentGroup.set(data)),
			catchError((err) => {
				throw new Error(err.error?.title || err.message);
			}),
			finalize(() => this.isLoadingDetails.set(false)),
		);
	}

	getOwnerOptions(groupId: string) {
		return this.apiService
			.get<PolicyOwner[]>(`/android/groups/getowneroptions/${groupId}`)
			.pipe(tap((data) => this.ownerOptions.set(data)));
	}

	simpleUpdateGroup(groupId: string, data: GroupGeneralUpdate) {
		return this.apiService.put<void>(`/android/groups/${groupId}`, data);
	}

	updateApps(groupId: string, data: GroupAppsUpdate[], pushChanges: boolean = false) {
		return this.apiService.put<void>(`/android/groups/appstatus/${groupId}`, data, {
			params: { pushChanges },
		});
	}

	updateRestrictions(
		groupId: string,
		data: GroupRestrictionsUpdate[],
		pushChanges: boolean = false,
	) {
		return this.apiService.put<void>(`/android/groups/settingvalue/${groupId}`, data, {
			params: { pushChanges },
		});
	}
}
