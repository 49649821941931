import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
	selector: 'csd-app-policies-layout',
	standalone: true,
	imports: [RouterOutlet],
	templateUrl: './policies-layout.component.html',
	styleUrl: './policies-layout.component.scss',
})
export class PoliciesLayoutComponent {}
