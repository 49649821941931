import { VpnFilters } from './internet.types';

export const VPN_FILTER_OPTIONS = [
	{
		label: 'Direct',
		value: VpnFilters.Direct,
	},
	{
		label: 'SecureLock',
		value: VpnFilters.SecureLock,
	},
	{
		label: 'SafeSentry',
		value: VpnFilters.SafeSentry,
	},
];

export const PROXY_PAC_REGEX = /^(http|https):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/;
