import { Routes } from '@angular/router';
import { DeviceLayoutComponent } from '@layout/device-layout/device-layout.component';

import { deviceDetailsResolver } from '../device-details/device-details.resolver';
import deviceDetailsRoutes from '../device-details/device-details.routes';
import { DevicesComponent } from './devices.component';

const baseTitle = '| Devices | eMyPeople';

export default <Routes>[
	{
		path: '',
		component: DevicesComponent,
		data: { breadcrumb: '' },
	},
	{
		path: 'add-device',
		loadComponent: () => import('@feature/device-add/device-add.component'),
		title: `Add Device ${baseTitle}`,
		data: { breadcrumb: 'Add Device' },
	},
	{
		path: ':deviceId',
		component: DeviceLayoutComponent,
		resolve: { device: deviceDetailsResolver },
		data: { breadcrumb: (data: any) => data?.device?.name },
		children: deviceDetailsRoutes,
	},
];
