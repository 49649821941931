import { Component, inject, OnInit, output, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
	MatAutocompleteModule,
	MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { ApiService } from '@core/api/api.service';
import { CustomersService } from '@feature/customers/customers.service';
import { catchError, debounceTime } from 'rxjs';

import { CustomersStore } from '../../customers/customers.store';
import { UserStore } from '../../user/user.store';

@Component({
	selector: 'csd-app-customer-search',
	standalone: true,
	imports: [
		MatAutocompleteModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatProgressSpinnerModule,
		MatTooltipModule,
		ReactiveFormsModule,
	],
	templateUrl: './customer-search.component.html',
	styleUrl: './customer-search.component.scss',
})
export class CustomerSearchComponent implements OnInit {
	readonly #userStore = inject(UserStore);
	readonly #customersStore = inject(CustomersStore);
	#apiService = inject(ApiService);
	#customersService = inject(CustomersService);
	#router = inject(Router);

	focusout = output<string>();
	optionSelected = output<string>();

	isLoading = signal<boolean>(false);
	isLoadingCustomer = this.#customersService.isLoading;
	minSearchTermLength = 3;
	options = signal<Record<string, string>[]>([]);
	searchControl = new FormControl();
	searchTooltip = 'You can search by Name, Address, Phone Number or Email.';

	ngOnInit(): void {
		this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
			if (value.length >= this.minSearchTermLength) {
				this.getCustomer(value);
			}
		});
	}

	autocompleteSelected(event: MatAutocompleteSelectedEvent) {
		const customerId = event.option?.value?.customerId;

		this.searchControl.setValue('');
		this.#customersStore.updateSelectedCustomer(customerId);
		this.optionSelected.emit(customerId);
		this.#router.navigate([customerId, 'overview']);
	}

	getCustomer(value: string) {
		const sub = this.#userStore.user().sub;

		this.isLoading.set(true);

		this.#apiService
			.get<any>(`/search?sub=${sub}&searchTerm=${encodeURI(value)}`, {})
			.pipe(
				catchError(() => {
					this.isLoading.set(false);
					throw new Error('Error getting customers with the search criteria');
				}),
			)
			.subscribe((response) => {
				this.isLoading.set(false);
				this.options.set(response);
			});
	}

	searchFocusOut() {
		this.focusout.emit(this.searchControl.value);
	}
}
