<div class="stats-page pb-5" [formGroup]="form">
	<h2 class="stats-page__device-name">
		<csd-app-name [text]="device().name" (changed)="updateDevice('name')" />
	</h2>

	<section class="details-section">
		<div class="details-section__status">
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">campaign</mat-icon>
					Status
				</span>
				<span
					class="status-item__value status-button"
					[class.status-button--active]="device().active"
					>{{ device().active ? 'Active' : 'Disabled' }}</span
				>
			</p>
			<p class="status-item pb-5">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">schedule</mat-icon>
					Last Seen
				</span>
				<span class="status-item__value">{{ device().updated | date }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">id_card</mat-icon>
					License #
				</span>
				<span class="status-item__value">{{ device().license }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">verified</mat-icon>
					Version
				</span>
				<span class="status-item__value">{{
					device().appDisplayVersion || device().appVersion
				}}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled">data_table</mat-icon>
					Updates Channel
				</span>
				<span class="status-item__value">
					<mat-select
						class="channel-selection"
						hideSingleSelectionIndicator
						formControlName="releaseChannel"
						[compareWith]="selectCompareValue"
						(selectionChange)="updateDevice('releaseChannel')"
					>
						@for (channel of updatesChannels; track channel.value) {
							<mat-option [value]="channel.value">{{ channel.label }}</mat-option>
						}
					</mat-select>
				</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">
					<mat-icon inline class="material-symbols-filled align-sub">lock</mat-icon>
					Unlock Code
				</span>
				<span class="status-item__value">
					<csd-app-uninstall-key
						[text]="device().uninstallKey"
						(changed)="updateDevice('uninstallKey')"
					/>
				</span>
			</p>
		</div>
		<div class="details-section__device-info">
			<p class="status-item">
				<span class="status-item__label">Manufacturer •</span>
				<span class="status-item__value">{{ device().manufacturer }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">Name •</span>
				<span class="status-item__value">{{ device().name }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">Model •</span>
				<span class="status-item__value">{{ device().model }}</span>
			</p>
			<p class="status-item pb-3">
				<span class="status-item__label">Serial Number •</span>
				<span class="status-item__value">{{ device().serialNumber }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">IMEI •</span>
				<span class="status-item__value">{{ device().imeI1 }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">Phone Number •</span>
				<span class="status-item__value">{{ device().imeI1Carrier }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">IMEI 2 •</span>
				<span class="status-item__value">{{ device().imeI2 }}</span>
			</p>
			<p class="status-item">
				<span class="status-item__label">Phone Number 2 •</span>
				<span class="status-item__value">{{ device().imeI2Carrier }}</span>
			</p>
		</div>
	</section>
	<section class="device-section">
		<csd-app-header-divider text="Policy" />
		<div class="device-section__content">
			<mat-select
				class="group-dropdown"
				formControlName="group"
				[compareWith]="selectCompareValue"
				(selectionChange)="updateDevice('group')"
				hideSingleSelectionIndicator
			>
				@if (!groups().length) {
					<mat-option [value]="device().group.id">{{ device().group.name }}</mat-option>
				}
				@for (group of groups(); track $index + group.id) {
					<mat-option [value]="group.id">{{ group.name }}</mat-option>
				}
			</mat-select>
		</div>
	</section>
	<section class="device-section">
		<csd-app-header-divider text="Device Commands" />
		<div class="device-section__content device-actions">
			@for (button of commandButtons; track button.command) {
				<csd-app-button
					classList="w-full"
					type="outlined"
					variation="primary"
					[icon]="button.icon"
					[disabled]="loadingCmdUpdates()"
					(click)="sendCommand(button.command)"
				>
					{{ button.label }}
				</csd-app-button>
			}
		</div>
	</section>
</div>
