<div class="inside-menu">
	@for (item of itemsList(); track item.icon) {
		<a
			class="inside-menu__link"
			[class.inside-menu__link--active]="item.active"
			[class.inside-menu__link--disabled]="item.disabled"
			routerLink="{{ item.route || '.' }}"
			(click)="onClick($event, item)"
		>
			<button class="flex flex-col items-center" [disabled]="item.disabled">
				<span class="text-3xl leading-none relative">
					<mat-icon inline>{{ item.icon }}</mat-icon>
					@if (item.timer) {
						<span class="absolute text-lg text-error-tertiary -bottom-3 -right-1">
							<mat-icon inline class="material-symbols-filled">schedule</mat-icon>
						</span>
					}
				</span>
				<span class="link__label">{{ item.label }}</span>
			</button>
		</a>
	}
</div>
