<div class="flex flex-col items-stretch">
  <h1 class="text-3xl text-center font-semibold text-teal-800 md:text-4xl lg:pt-0 2xl:text-6xl">
    Connect Your Account
  </h1>
  <h5 class="w-full p-2 text-center text-md md:text-xl lg:p-5 2xl:text-2xl">
    Almost there, but first confirm you are the owner of this account.
  </h5>
  <csd-app-loader [isLoading]="isLoading()">
    @switch (step()) {
      @case ('start') {
        <csd-app-guide [email]="email()" (continued)="step.set('options')"></csd-app-guide>
      }
      @case ('options') {
        <csd-app-options
          [numbersList]="numbersList()"
          (changed)="optionSelected.set($event)"
          (canceled)="step.set('start')"
          (submitted)="step.set('verify')"
        ></csd-app-options>
      }
      @case ('verify') {
        <csd-app-verify-code
          [email]="email()"
          [validateOption]="optionSelected()"
          (canceled)="step.set('options')"
        ></csd-app-verify-code>
      }
    }
  </csd-app-loader>
</div>
