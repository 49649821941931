import { mapPolicyApps } from '@root/app/utils/map-policy-apps';

import { Group, GroupServerResponse } from '../policy-details.types';
import { sortAndroidSettings } from './sort-android-settings';

export const mapGroupResponse = (data: GroupServerResponse): Group => {
	const policyAndroidSettings = [...data.policy.policyAndroidSettings];

	policyAndroidSettings.sort(sortAndroidSettings);
	const policyAppJoins = mapPolicyApps(data.policy.policyAppJoins);

	return {
		id: data.id,
		name: data.name,
		churchCode: data.churchCode,
		customerUserCode: data.customerUserCode,
		logoLink: data.logoLink,
		policy: {
			id: data.policy.id,
			active: data.policy.active,
			created: data.policy.created,
			updated: data.policy.updated,
			vpnModeAllow: data.policy.vpnModeAllow,
			policyAndroidSettings,
			policyAppJoins,
		},
		policyId: data.policyId,
		created: data.created,
		updated: data.created,
		owner: data.owner,
		pool: data.pool,
		active: data.active,
	};
};
