import { Component, effect, EffectRef, inject, input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@core/api/api.service';
import { LoaderFullScreenComponent } from '@ui/loader-full-screen/loader-full-screen.component';
import { Subscription } from 'rxjs';

import { UserStore } from '../../user/user.store';

@Component({
	selector: 'csd-app-login-callback',
	standalone: true,
	imports: [LoaderFullScreenComponent],
	templateUrl: './login-callback.component.html',
})
export class LoginCallbackComponent implements OnInit, OnDestroy {
	readonly #store = inject(UserStore);
	private apiService = inject(ApiService);
	private router = inject(Router);

	statusText: string = 'Loading';

	code = input<string>('');
	state = input<string>('');

	effectRef!: EffectRef;
	subscriptions: Subscription[] = [];

	constructor() {
		this.effectRef = effect(() => {
			if (this.#store.user().sub) {
				this.retrieveListContent();
			}
		});
	}

	ngOnInit() {
		this.isValidSession();
	}

	ngOnDestroy(): void {
		this.effectRef.destroy();
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	isValidSession() {
		if (this.code() && this.state()) {
			return;
		}

		this.router.navigate(['/authentication/login']);
	}

	retrieveListContent() {
		this.statusText = 'Authorizing';

		const listSub$ = this.apiService
			.get(`/access/listcontent/${this.#store.user().sub}`)
			.subscribe(this.validateList.bind(this));

		this.subscriptions.push(listSub$ as Subscription);
	}

	validateList(content: unknown) {
		const isEmptyList = !content || (Array.isArray(content) && content.length === 0);

		if (isEmptyList) {
			this.router.navigate(['/onboarding'], { replaceUrl: true });
		}

		this.validateRedirectRoute();
	}

	validateRedirectRoute() {
		const isLoginCallback = this.router.url.match(/login-callback/gi);

		if (!isLoginCallback) {
			return;
		}

		this.router.navigate(['/']);
	}
}
