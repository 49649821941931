export const POLICY_LINKS = [
	{
		label: 'General',
		icon: 'notes',
		route: 'general',
	},
	{
		label: 'Apps',
		icon: 'apps',
		route: 'apps',
	},
	{
		label: 'Restrictions',
		icon: 'phonelink_setup',
		route: 'restrictions',
	},
];
