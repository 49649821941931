import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '@ui/button/button.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { InlineMessageComponent } from '@ui/inline-message/inline-message.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { ModalService } from '@ui/modal/modal.service';
import { ModalTypes } from '@ui/modal/modal.types';
import { filter, Subscription } from 'rxjs';

import { CustomersStore } from '../customers/customers.store';
import { DeviceDetailsService } from '../device-details/device-details.service';
import { AndroidDeviceBase } from '../device-details/device-details.types';
import { MODAL_CONFIG } from './devices.constants';
import { DevicesService } from './devices.service';
import { DevicesStore } from './devices.store';

@Component({
	selector: 'csd-app-devices',
	standalone: true,
	templateUrl: './devices.component.html',
	styleUrl: './devices.component.scss',
	imports: [
		ButtonComponent,
		EmptyStateComponent,
		InlineMessageComponent,
		LoaderComponent,
		MatDividerModule,
		MatIconModule,
		MatRippleModule,
		MatTableModule,
		RouterLink,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesComponent implements OnInit, OnDestroy {
	readonly #customersStore = inject(CustomersStore);
	readonly #devicesStore = inject(DevicesStore);
	#devicesService = inject(DevicesService);
	#deviceDetailsService = inject(DeviceDetailsService);
	#modalService = inject(ModalService);
	#router = inject(Router);

	mdmColumns = ['os', 'deviceName', 'licenseNumber', 'timer', 'delete'];
	connectionsColumns = ['device', 'identifier', 'timer', 'delete'];
	customer = this.#customersStore.selectedCustomer;
	isLoadingDevices = this.#devicesService.isLoading;
	isStartingRoute = signal<boolean>(false);
	groupSubscriptions = this.#devicesStore.subscriptionsCompleteList;
	areDevicesLoaded = signal<boolean>(false);
	subscriptions: Subscription[] = [];

	isLoadingDetails = computed(() => {
		return this.isStartingRoute() && this.#deviceDetailsService.isLoading();
	});

	constructor() {
		effect(
			() => {
				const { code } = this.customer() || {};

				if (!code) {
					this.areDevicesLoaded.set(false);
					return;
				}

				if (this.#devicesStore.subscriptionsList().length) {
					return;
				}

				const devicesSub$ = this.#devicesService.getDevices(code).subscribe(() => {
					this.areDevicesLoaded.set(true);
				});

				this.subscriptions.push(devicesSub$);
			},
			{ allowSignalWrites: true },
		);
	}

	ngOnInit(): void {
		this.setWorkOrder(0);

		const sub$ = this.#router.events
			.pipe(filter((event) => event instanceof NavigationStart))
			.subscribe((event) => {
				const isLoadingItem = (event as NavigationStart).url.match(/devices\/[a-z0-9]/gi);
				this.isStartingRoute.set(!!isLoadingItem);
			});

		this.subscriptions.push(sub$);
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	openDevice(row: AndroidDeviceBase) {
		this.setWorkOrder(row.workOrder);
		this.#router.navigate([this.customer()?.code, 'devices', row.id]);
	}

	onActionSuccess(data?: { success: boolean }) {
		this.#modalService.close();

		if (!data?.success) {
			return;
		}
	}

	async openActionModal(event: Event, row: AndroidDeviceBase, modal: ModalTypes) {
		event.preventDefault();
		event.stopPropagation();

		this.#devicesStore.updateSelectedDevice(row.id);
		await this.#modalService.open(modal, row, MODAL_CONFIG);
		this.#modalService.afterClose(this.onActionSuccess.bind(this));
	}

	setWorkOrder(workOrder: number) {
		this.#devicesStore.updateSelectedSubscription(workOrder);
	}
}
