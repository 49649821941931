import { Component } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';

@Component({
	selector: 'csd-app-new-customer',
	standalone: true,
	imports: [],
	templateUrl: './new-customer.component.html',
	styleUrl: './new-customer.component.scss',
})
export class NewCustomerComponent {
	constructor(private authService: AuthService) {}

	logout() {
		this.authService.logout().subscribe();
	}
}
