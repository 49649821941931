import { environment } from '@root/environments/environment';
import { LogLevel, PassedInitialConfig } from 'angular-auth-oidc-client';

const appLocation = window.location.origin;

export const authConfig: PassedInitialConfig = {
	config: {
		authority: environment.authAuthority,
		redirectUrl: `${appLocation}/authentication/login-callback`,
		postLoginRoute: `/authentication/login-callback`,
		postLogoutRedirectUri: `${appLocation}/authentication/logout`,
		clientId: environment.authClientId,
		scope: 'openid profile email offline_access',
		responseType: 'code',
		silentRenew: true,
		silentRenewUrl: `${appLocation}/silent-renew.html`,
		useRefreshToken: true,
		maxIdTokenIatOffsetAllowedInSeconds: 180,
		secureRoutes: [appLocation, environment.baseApi],
		logLevel: LogLevel.None,
	},
};
