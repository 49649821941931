import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError, forkJoin } from 'rxjs';

import { PolicyDetailsService } from './policy-details.service';
import { Group, PolicyOwner } from './policy-details.types';

export const policyDetailsResolver: ResolveFn<[Group, PolicyOwner[]]> = (route) => {
	const policyDetailsService = inject(PolicyDetailsService);
	const groupId = route.paramMap.get('groupId') as string;
	const requestPolicy = policyDetailsService.getGroup(groupId);
	const requestOwners = policyDetailsService.getOwnerOptions(groupId);

	return forkJoin([requestPolicy, requestOwners]).pipe(
		catchError(() => {
			throw new Error('Error getting policy details. Verify the group ID and try again.');
		}),
	);
};
