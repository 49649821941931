import { Injectable } from '@angular/core';

import { AndroidDevice } from '../device-details.types';
import { InternetFormShape, VpnFilters } from './internet.types';

@Injectable()
export class InternetService {
	buildFormData(formValue: InternetFormShape, device: AndroidDevice) {
		return {
			name: device.name,
			group: device.group.id,
			uninstallKey: device.uninstallKey,
			proxyConfigURL: formValue.vpn ? device.proxyConfigURL : formValue.proxyPacUrl,
			proxyExceptions: device.proxyExceptions,
			browserProxyConfigURL: formValue.vpn ? device.browserProxyConfigURL : formValue.browserUrl,
			browserProxyExceptions: device.browserProxyExceptions,
			vpnOn: formValue.vpn,
			vpnProxy: formValue.vpn ? formValue.vpnProxy : device.vpnProxy ?? VpnFilters.Direct,
			releaseChannel: device.releaseChannel,
			workOrder: device.workOrder,
		};
	}
}
