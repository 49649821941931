<div class="device-restrictions">
	@for (item of device().policy.policyAndroidSettings; track item.settingId; let i = $index) {
		<div class="relative">
			<csd-app-app-checkbox
				class="w-full"
				[text]="item.setting.name"
				[checked]="item.value"
				[showReset]="item.differentThanGroup && !isItemResetting()"
				(change)="changeSelection($event, $index)"
				(reset)="resetSetting(item)"
			></csd-app-app-checkbox>
			@if (item.settingId === isItemResetting()) {
				<div class="absolute bottom-2 right-3">
					<csd-app-loading-icon />
				</div>
			}
		</div>
	}
</div>
