@if (shouldDisplayBanner()) {
	<csd-app-banner type="danger">
		<div class="flex justify-between items-center gap-3">
			<span>
				Temporary open until {{ device().overrideFinishedUtc | date: 'hh:mma MMM d, y' }}. Any
				changes on this page will only be temporary
			</span>
			<csd-app-button
				class="text-layout-stone-gray"
				type="tonal"
				size="small"
				classList="min-w-24"
				[disabled]="isRemovingTimer()"
				(click)="closeDevice()"
			>
				@if (isRemovingTimer()) {
					<csd-app-loading-icon />
				} @else {
					Close Device
				}
			</csd-app-button>
		</div>
	</csd-app-banner>
}
<div class="device-details" [class.mt-8]="shouldDisplayBanner()">
	<csd-app-section-menu [itemsList]="devicesLinks()"></csd-app-section-menu>
	<div class="w-full md:grow">
		<router-outlet></router-outlet>
	</div>
</div>
