import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { UserImageComponent } from '@ui/user-image/user-image.component';

import { CustomersStore } from '../../customers/customers.store';

@Component({
	selector: 'csd-app-customer-info',
	standalone: true,
	imports: [MatIconModule, MatRippleModule, UserImageComponent],
	templateUrl: './customer-info.component.html',
	styleUrl: './customer-info.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerInfoComponent {
	#store = inject(CustomersStore);
	#router = inject(Router);

	customer = this.#store.selectedCustomer;
	userImg = signal<string>('');

	userName = computed<string>(() => {
		return this.#store.selectedCustomer()?.name || '';
	});

	removeSelection() {
		this.#store.updateSelectedCustomer('');
		this.#router.navigate(['/']);
	}
}
