import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';

@Component({
	selector: 'csd-app-error-layout',
	standalone: true,
	imports: [EmptyStateComponent],
	templateUrl: './error-layout.component.html',
	styleUrl: './error-layout.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorLayoutComponent {}
