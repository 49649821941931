import { inject } from '@angular/core';
import { MaybeAsync, ResolveFn } from '@angular/router';
import { catchError } from 'rxjs';

import { CustomersService } from '../customers/customers.service';
import { CustomersStore } from '../customers/customers.store';
import { Customer } from './customer.types';

export const customerResolver: ResolveFn<MaybeAsync<Customer | undefined>> = (route, state) => {
	const store = inject(CustomersStore);
	const customersService = inject(CustomersService);
	const customerId = route.paramMap.get('customerId') as string;

	if (customerId === 'public') {
		return;
	}

	const customer = store.customers()[customerId] as Customer;

	if (customer) {
		return customer;
	}

	const requestCustomer = customersService.getCustomer(customerId);

	return requestCustomer.pipe(
		catchError(() => {
			throw new Error('Error getting customer information. Verify the customer ID and try again.');
		}),
	);
};
