import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { triggerOpenClose } from '@root/app/animations';

@Component({
	selector: 'csd-app-general',
	standalone: true,
	imports: [ReactiveFormsModule, FormsModule, MatInputModule],
	templateUrl: './general.component.html',
	styleUrl: './general.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [triggerOpenClose],
})
export class GeneralComponent {
	rootFormGroup = inject(FormGroupDirective);

	get form() {
		return this.rootFormGroup.control;
	}

	get hasNameError() {
		return this.form.dirty && this.form.get('name')?.invalid;
	}
}
