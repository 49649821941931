import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { DevicesStore } from '../devices/devices.store';
import { DeviceDetailsService } from './device-details.service';
import { AndroidDevice } from './device-details.types';

export const deviceDetailsResolver: ResolveFn<unknown> = (route) => {
	const devicesStore = inject(DevicesStore);
	const deviceDetailsService = inject(DeviceDetailsService);
	const deviceId = route.paramMap.get('deviceId') as string;

	if (!deviceId) {
		return;
	}

	devicesStore.updateSelectedDevice(deviceId);
	const device = devicesStore.devices()[deviceId];
	const hasDetails = device && (device as AndroidDevice).policy;

	if (device && hasDetails) {
		return device;
	}

	return deviceDetailsService.getDevice(deviceId);
};
