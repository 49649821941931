<ng-container>
  <div class="w-full px-4 pt-6 md:px-12">
    <div class="w-full">
      <ul class="-my-2">
        <li class="relative py-2">
          <div class="mb-1 flex items-center">
            <div class="absolute left-0 h-full -translate-x-1/2 translate-y-3 self-start bg-slate-200 w-0.5 ml-2.5" aria-hidden="true"></div>
            <div class="absolute left-0 rounded-full bg-teal-800" aria-hidden="true"><svg class="h-5 w-5 fill-current text-white" viewBox="0 0 20 20">
                <path d="M14.4 8.4L13 7l-4 4-2-2-1.4 1.4L9 13.8z"></path>
              </svg></div>
            <h3 class="pl-9 text-lg font-bold text-slate-800">Active Account</h3>
          </div>
          <div class="pl-9">
            There is an active account with your email
            <span class="rounded-md bg-slate-100 px-1 font-semibold text-slate-500">{{email()}}</span>
            in our system.</div>
        </li>
        <li class="relative py-2">
          <div class="mb-1 flex items-center">
            <div class="absolute left-0 h-full -translate-x-1/2 translate-y-3 self-start bg-slate-200 w-0.5 ml-2.5" aria-hidden="true"></div>
            <div class="absolute left-0 rounded-full bg-white" aria-hidden="true"><svg class="h-5 w-5 fill-current text-slate-400" viewBox="0 0 20 20">
                <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path>
              </svg></div>
            <h3 class="pl-9 text-lg font-bold text-slate-800">Send a Security Code</h3>
          </div>
          <div class="pl-9">Select a contact method on the next screen</div>
        </li>
        <li class="relative py-2">
          <div class="mb-1 flex items-center">
            <div class="absolute left-0 rounded-full bg-white" aria-hidden="true"><svg class="h-5 w-5 fill-current text-slate-400" viewBox="0 0 20 20">
                <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"></path>
              </svg></div>
            <h3 class="pl-9 text-lg font-bold text-slate-800">Verification Complete</h3>
          </div>
          <div class="pl-9">Enter the code you received to complete the verification process.</div>
        </li>
      </ul>
    </div>
  </div>

  <csd-app-actions
    cancelLabel="Log Out"
    continueLabel="Continue"
    (canceled)="logout()"
    (submitted)="continue()"
  ></csd-app-actions>
</ng-container>
