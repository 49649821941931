import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { CustomerSelectionComponent } from '@feature/customer-selection/customer-selection.component';
import { CustomersService } from '@feature/customers/customers.service';
import { CustomersStore } from '@feature/customers/customers.store';
import { HistoryComponent } from '@feature/history/history.component';
import { UserService } from '@feature/user/user.service';
import { BreadcrumbComponent } from '@ui/breadcrumb/breadcrumb.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { LoaderComponent } from '@ui/loader/loader.component';

import { SidebarComponent } from './sidebar/sidebar.component';

@Component({
	selector: 'csd-app-main-layout',
	standalone: true,
	templateUrl: './main-layout.component.html',
	styleUrl: './main-layout.component.scss',
	imports: [
		BreadcrumbComponent,
		CustomerSelectionComponent,
		HistoryComponent,
		LoaderComponent,
		MatIconModule,
		MatSidenavModule,
		RouterOutlet,
		SidebarComponent,
		EmptyStateComponent,
	],
})
export class MainLayoutComponent {
	readonly #store = inject(CustomersStore);
	#breakpointObserver = inject(BreakpointObserver);
	#customersService = inject(CustomersService);
	#userService = inject(UserService);

	customer = this.#store.selectedCustomer;
	isLoadingCustomer = this.#customersService.isLoading;
	isLoadingUserGroups = this.#userService.isLoadingGroups;
	isMobile = signal<boolean>(false);
	shouldDisplayDashboard = this.#userService.shouldDisplayDashboard;
	shouldOpenSidenav = signal<boolean>(true);

	constructor() {
		this.startBreakpointObserver();
	}

	startBreakpointObserver() {
		this.#breakpointObserver.observe(['(max-width: 1024px)']).subscribe((screenSize) => {
			this.shouldOpenSidenav.set(!screenSize.matches);
			this.isMobile.set(screenSize.matches);
		});
	}

	openSideNav() {
		this.shouldOpenSidenav.set(true);
	}

	onChangeSidenav(isOpen: boolean) {
		this.shouldOpenSidenav.set(isOpen);
	}
}
