<mtx-alert
	class="banner"
	[class.banner--danger]="type() === 'danger'"
	[type]="type()"
	[elevation]="elevation"
	[dismissible]="dismissible()"
	(closed)="onClosed($event)"
>
	<ng-content></ng-content>
</mtx-alert>
