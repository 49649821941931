import { Component, input, OnInit, output, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { LoaderComponent } from '../../../../ui/loader/loader.component';
import { ActionsComponent } from '../actions/actions.component';

export interface OptionSelection {
	method: string;
	number: number;
}

@Component({
	selector: 'csd-app-options',
	standalone: true,
	templateUrl: './options.component.html',
	styleUrl: './options.component.scss',
	imports: [ActionsComponent, ReactiveFormsModule, LoaderComponent],
})
export class OptionsComponent implements OnInit {
	form: FormGroup = this.fb.group({
		method: '',
	});
	isLoading = signal<boolean>(false);

	numbersList = input.required<number[]>();

	changed = output<OptionSelection>();
	canceled = output<void>();
	submitted = output<void>();

	constructor(private fb: FormBuilder) {}

	get isOptionsSelected() {
		return !!this.form.get('method')?.value;
	}

	ngOnInit() {
		this.form.valueChanges.subscribe((value) => {
			const [method, number] = value.method.split('-');

			this.changed.emit({ method, number });
		});
	}

	requestCode() {
		this.submitted.emit();
	}

	stepBack() {
		this.form.reset({ method: '' });
		this.canceled.emit();
	}
}
