import { Component, inject, OnInit, signal } from '@angular/core';
import { LoaderComponent } from '@ui/loader/loader.component';

import { UserStore } from '../../user/user.store';
import { STEPS } from './connect.constants';
import { ConnectService } from './connect.service';
import { GuideComponent } from './guide/guide.component';
import { OptionsComponent, OptionSelection } from './options/options.component';
import { VerifyCodeComponent } from './verify-code/verify-code.component';

@Component({
	selector: 'csd-app-connect',
	standalone: true,
	templateUrl: './connect.component.html',
	styleUrl: './connect.component.scss',
	imports: [GuideComponent, LoaderComponent, OptionsComponent, VerifyCodeComponent],
})
export class ConnectComponent implements OnInit {
	readonly #store = inject(UserStore);
	#connectService = inject(ConnectService);

	email = signal<string>('');
	isLoading = signal<boolean>(false);
	numbersList = signal<number[]>([]);
	optionSelected = signal<OptionSelection>({} as OptionSelection);
	step = signal<string>(STEPS[0]);
	steps = STEPS;

	constructor() {}

	ngOnInit() {
		const userData = this.#store.user();

		this.isLoading.set(true);
		this.email.set(userData?.email);
		this.#connectService.checkEmail(userData?.email, this.loadNumbersList.bind(this));
	}

	loadNumbersList() {
		this.#connectService.getNumbersList(this.email(), this.updateNumbersList.bind(this));
	}

	updateNumbersList(list: number[]) {
		this.isLoading.set(false);
		this.numbersList.set(list);
	}

	showVerify() {
		this.step.set(this.steps[2]);
	}
}
