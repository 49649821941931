import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { ButtonComponent } from '@ui/button/button.component';
import { NotificationService } from '@ui/notification/notification.service';
import { last, map, tap } from 'rxjs';

import { DevicesStore } from '../../devices/devices.store';
import { DeviceDetailsService } from '../device-details.service';

@Component({
	selector: 'csd-app-apk-install',
	standalone: true,
	imports: [ButtonComponent],
	templateUrl: './apk-install.component.html',
	styleUrl: './apk-install.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApkInstallComponent {
	readonly #devicesStore = inject(DevicesStore);
	#deviceDetailsService = inject(DeviceDetailsService);
	#notificationService = inject(NotificationService);

	device = this.#devicesStore.selectedDevice;
	sendDisabled = signal(true);
	sending = signal(false);
	file: File | undefined;
	progress = signal('0');

	handleFileInput(event: Event) {
		this.file = (event.target as HTMLInputElement).files?.[0];
		if (!this.file) {
			return;
		}

		this.sendDisabled.set(false);
	}

	sendAPK() {
		if (this.sendDisabled()) {
			return;
		}

		this.sending.set(true);
		this.sendDisabled.set(true);

		this.#deviceDetailsService
			.uploadApk(this.device().id, this.file)
			.pipe(
				map((progressEvent) => this.getEventMessage(progressEvent)),
				tap((progress) => this.progress.set(progress)),
				last(),
			)
			.subscribe((response) => {
				this.sending.set(false);
				this.progress.set('');
				this.#notificationService.openSuccess({
					message: 'Successfully sent APK to device',
				});
			});
	}

	private getEventMessage(event: HttpEvent<any>) {
		switch (event.type) {
			case HttpEventType.UploadProgress:
				const percentDone = event.total ? Math.round((100 * event.loaded) / event.total) : 0;
				return `${percentDone}`;

			case HttpEventType.Response:
				return '100';

			default:
				return '';
		}
	}
}
