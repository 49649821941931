import { Component } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';

@Component({
	selector: 'csd-app-login',
	standalone: true,
	imports: [],
	templateUrl: './login.component.html',
	styleUrl: './login.component.scss',
})
export class LoginComponent {
	isLoading: boolean = false;
	constructor(private authService: AuthService) {}

	login() {
		this.isLoading = true;
		this.authService.login();
	}
}
