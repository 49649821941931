import {
	ChangeDetectionStrategy,
	Component,
	effect,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '@ui/button/button.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { ModalService } from '@ui/modal/modal.service';
import { NotificationService } from '@ui/notification/notification.service';
import { SectionComponent } from '@ui/section/section.component';
import { catchError, Subscription } from 'rxjs';

import { CustomersStore } from '../customers/customers.store';
import { PolicyDetailsService } from './../policy-details/policy-details.service';
import { PoliciesService } from './policies.service';
import { GroupFromList, PoliciesByCustomer } from './policies.types';
import { SortableListComponent } from './sortable-list/sortable-list.component';
import { SortableItem, SortableList, SortableTagType } from './sortable-list/sortable-list.types';

@Component({
	selector: 'csd-app-policies',
	standalone: true,
	imports: [
		ButtonComponent,
		EmptyStateComponent,
		LoaderComponent,
		MatIconModule,
		RouterLink,
		SectionComponent,
		SortableListComponent,
	],
	templateUrl: './policies.component.html',
	styleUrl: './policies.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoliciesComponent implements OnInit, OnDestroy {
	readonly #store = inject(CustomersStore);
	modalService = inject(ModalService);
	notificationService = inject(NotificationService);
	policiesService = inject(PoliciesService);
	policyDetailsService = inject(PolicyDetailsService);
	router = inject(Router);

	customer = this.#store.selectedCustomer;
	isLoading = this.policiesService.isLoading;
	isLoadingDetails = this.policyDetailsService.isLoadingDetails;
	lengthToExpand = 3;
	selectedPolicyName = signal<string>('');
	subscriptions: Subscription[] = [];

	customerPolicies = signal<SortableList>([]);
	otherPolicies = signal<SortableList>([]);

	constructor() {
		effect(
			() => {
				if (this.customer()?.code) {
					this.loadCustomerPolicies();
				}
			},
			{
				allowSignalWrites: true,
			},
		);
	}

	ngOnInit(): void {
		const customerCode = this.customer()?.code;
		const isPublicRoute = this.router.url.split('/').filter((el) => el)[0] === 'public';

		if (customerCode && !isPublicRoute) {
			this.router.navigate([`${customerCode}/policies/`], { replaceUrl: true });
			return;
		}

		if (isPublicRoute) {
			this.loadPublicPolicies();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	async deletePolicy(item: SortableItem, section: 'customer' | 'public') {
		await this.modalService.open('delete-policy', {
			policy: item,
			customer: this.customer(),
			section,
		});
		this.modalService.afterClose(this.handleCloseModal.bind(this));
		this.selectedPolicyName.set(item.name);
	}

	handleCloseModal(data: unknown) {
		const policyName = this.selectedPolicyName();

		this.modalService.close();
		this.selectedPolicyName.set('');

		if (!data) {
			return;
		}

		this.notificationService.openSuccess({
			message: `Policy <strong>${policyName}</strong> was deleted successfully`,
		});

		if (this.customer()?.code) {
			this.loadCustomerPolicies();
			return;
		}

		this.loadPublicPolicies();
	}

	loadCustomerPolicies() {
		const sub$ = this.policiesService
			.getByCustomer(this.customer()?.code ?? '', this.customer()?.id ?? '')
			.pipe(
				catchError(() => {
					this.notificationService.openError({
						message: 'An error occurred while trying to load policies. Please try again later.',
					});

					return [];
				}),
			)
			.subscribe((data) => {
				const { ownerPolicies, allPolicies } = data as PoliciesByCustomer;
				this.setPoliciesData(ownerPolicies, allPolicies);
			});

		this.subscriptions.push(sub$);
	}

	loadPublicPolicies() {
		const sub$ = this.policiesService.getPublicPolicies().subscribe((data) => {
			const { allPolicies } = data;
			this.setPoliciesData([], allPolicies);
		});

		this.subscriptions.push(sub$);
	}

	setPoliciesData(ownerPolicies: GroupFromList[] = [], otherPolicies: GroupFromList[] = []) {
		const ownerDataWithLink = ownerPolicies.map((item) => ({
			...item,
			link: `./${item.id}`,
			tag: this.getTagData(item, true),
		}));
		const otherDataWithLink = otherPolicies.map((item) => ({
			...item,
			link: `./${item.id}`,
			tag: this.getTagData(item),
		}));

		this.customerPolicies.set(ownerDataWithLink);
		this.otherPolicies.set(otherDataWithLink);
	}

	getTagData(item: GroupFromList, isOwner: boolean = false) {
		if (isOwner || item.customUser) {
			return {
				name: isOwner ? (this.customer()?.code || '').toUpperCase() : 'Custom',
				type: 'user' as SortableTagType,
			};
		}

		if (item.churchCode) {
			return { name: 'Church', type: 'church' as SortableTagType };
		}

		return {
			name: 'Global',
			type: 'global' as SortableTagType,
		};
	}
}
