import { ComponentType } from '@angular/cdk/portal';

import { AppsComponent } from './apps/apps.component';
import { GeneralComponent } from './general/general.component';
import { AddPolicyDynamicComponent } from './policy-add.types';
import { RestrictionsComponent } from './restrictions/restrictions.component';

export const SECTIONS = ['general', 'restrictions', 'apps'];

export const DISPLAY_COMPONENTS: Record<string, ComponentType<AddPolicyDynamicComponent>> = {
	[SECTIONS[0]]: GeneralComponent,
	[SECTIONS[1]]: RestrictionsComponent,
	[SECTIONS[2]]: AppsComponent,
};

export const SECTION_FIELDS: Record<string, string[]> = {
	[SECTIONS[0]]: ['name', 'churchCode', 'customUser'],
	[SECTIONS[1]]: ['restrictions'],
	[SECTIONS[2]]: ['apps'],
};
