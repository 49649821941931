import * as i0 from '@angular/core';
import { EventEmitter, booleanAttribute, Component, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, Input, Output, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
const _c0 = ["*"];
function MtxAlert_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 0)(1, "button", 1);
    i0.ɵɵlistener("click", function MtxAlert_Conditional_1_Template_button_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1._onClosed());
    });
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(2, "svg", 2);
    i0.ɵɵelement(3, "path", 3);
    i0.ɵɵelementEnd()()();
  }
}
class MtxAlert {
  get _hostClassList() {
    return `mtx-alert-${this.type} mat-elevation-z${this.elevation}`;
  }
  constructor(_changeDetectorRef) {
    this._changeDetectorRef = _changeDetectorRef;
    /** The alert's type. Can be `default`, `info`, `success`, `warning` or `danger`. */
    this.type = 'default';
    /** Whether to display an inline close button. */
    this.dismissible = false;
    /** The alert's elevation (0~24). */
    this.elevation = 0;
    /** Event emitted when the alert closed. */
    this.closed = new EventEmitter();
  }
  _onClosed() {
    this._changeDetectorRef.markForCheck();
    this.closed.emit(this);
  }
  /** @nocollapse */
  static {
    this.ɵfac = function MtxAlert_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MtxAlert)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  /** @nocollapse */
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MtxAlert,
      selectors: [["mtx-alert"]],
      hostAttrs: ["role", "alert", 1, "mtx-alert"],
      hostVars: 4,
      hostBindings: function MtxAlert_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx._hostClassList);
          i0.ɵɵclassProp("mtx-alert-dismissible", ctx.dismissible);
        }
      },
      inputs: {
        type: "type",
        dismissible: [2, "dismissible", "dismissible", booleanAttribute],
        elevation: "elevation"
      },
      outputs: {
        closed: "closed"
      },
      exportAs: ["mtxAlert"],
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 2,
      vars: 1,
      consts: [[1, "mtx-alert-close"], ["mat-icon-button", "", "type", "button", "aria-label", "Close", 3, "click"], ["viewBox", "0 0 24 24", "width", "24px", "height", "24px", "fill", "currentColor", "focusable", "false"], ["d", "M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"]],
      template: function MtxAlert_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
          i0.ɵɵtemplate(1, MtxAlert_Conditional_1_Template, 4, 0, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.dismissible ? 1 : -1);
        }
      },
      dependencies: [MatIconButton],
      styles: [".mtx-alert{position:relative;display:block;padding:.75rem 1rem;margin-bottom:1rem;line-height:1.5;border:1px solid var(--mtx-alert-outline-color);border-radius:var(--mtx-alert-container-shape);background-color:var(--mtx-alert-background-color);color:var(--mtx-alert-text-color)}.mtx-alert.mtx-alert-info{border-color:var(--mtx-alert-info-outline-color);background-color:var(--mtx-alert-info-background-color);color:var(--mtx-alert-info-text-color)}.mtx-alert.mtx-alert-success{border-color:var(--mtx-alert-success-outline-color);background-color:var(--mtx-alert-success-background-color);color:var(--mtx-alert-success-text-color)}.mtx-alert.mtx-alert-warning{border-color:var(--mtx-alert-warning-outline-color);background-color:var(--mtx-alert-warning-background-color);color:var(--mtx-alert-warning-text-color)}.mtx-alert.mtx-alert-danger{border-color:var(--mtx-alert-danger-outline-color);background-color:var(--mtx-alert-danger-background-color);color:var(--mtx-alert-danger-text-color)}.mtx-alert-close{position:absolute;top:0;right:0;display:flex;align-items:center;justify-content:center;width:3rem;height:3rem}[dir=rtl] .mtx-alert-close{right:auto;left:0}.mtx-alert-dismissible{padding-right:3rem}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxAlert, [{
    type: Component,
    args: [{
      selector: 'mtx-alert',
      exportAs: 'mtxAlert',
      host: {
        'class': 'mtx-alert',
        '[class.mtx-alert-dismissible]': 'dismissible',
        'role': 'alert'
      },
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      standalone: true,
      imports: [MatIconButton],
      template: "<ng-content></ng-content>\n@if (dismissible) {\n  <div class=\"mtx-alert-close\">\n    <button mat-icon-button type=\"button\" aria-label=\"Close\" (click)=\"_onClosed()\">\n      <svg viewBox=\"0 0 24 24\" width=\"24px\" height=\"24px\" fill=\"currentColor\" focusable=\"false\">\n        <path d=\"M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z\" />\n      </svg>\n    </button>\n  </div>\n}\n",
      styles: [".mtx-alert{position:relative;display:block;padding:.75rem 1rem;margin-bottom:1rem;line-height:1.5;border:1px solid var(--mtx-alert-outline-color);border-radius:var(--mtx-alert-container-shape);background-color:var(--mtx-alert-background-color);color:var(--mtx-alert-text-color)}.mtx-alert.mtx-alert-info{border-color:var(--mtx-alert-info-outline-color);background-color:var(--mtx-alert-info-background-color);color:var(--mtx-alert-info-text-color)}.mtx-alert.mtx-alert-success{border-color:var(--mtx-alert-success-outline-color);background-color:var(--mtx-alert-success-background-color);color:var(--mtx-alert-success-text-color)}.mtx-alert.mtx-alert-warning{border-color:var(--mtx-alert-warning-outline-color);background-color:var(--mtx-alert-warning-background-color);color:var(--mtx-alert-warning-text-color)}.mtx-alert.mtx-alert-danger{border-color:var(--mtx-alert-danger-outline-color);background-color:var(--mtx-alert-danger-background-color);color:var(--mtx-alert-danger-text-color)}.mtx-alert-close{position:absolute;top:0;right:0;display:flex;align-items:center;justify-content:center;width:3rem;height:3rem}[dir=rtl] .mtx-alert-close{right:auto;left:0}.mtx-alert-dismissible{padding-right:3rem}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    _hostClassList: [{
      type: HostBinding,
      args: ['class']
    }],
    type: [{
      type: Input
    }],
    dismissible: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    elevation: [{
      type: Input
    }],
    closed: [{
      type: Output
    }]
  });
})();
class MtxAlertModule {
  /** @nocollapse */static {
    this.ɵfac = function MtxAlertModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MtxAlertModule)();
    };
  }
  /** @nocollapse */
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MtxAlertModule
    });
  }
  /** @nocollapse */
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, MtxAlert]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MtxAlertModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MtxAlert],
      exports: [MtxAlert]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MtxAlert, MtxAlertModule };
