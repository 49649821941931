import { Routes } from '@angular/router';

import { ConnectComponent } from './connect/connect.component';
import { NewCustomerComponent } from './new-customer/new-customer.component';

export default <Routes>[
	{
		path: '',
		component: ConnectComponent,
	},
	{
		path: 'new-customer',
		component: NewCustomerComponent,
	},
];
