<form [formGroup]="form" (submit)="onSave($event)">
	<div class="apps-section">
		@if (form.controls.apps) {
			<csd-app-apps-list />
		}

		@if (totalSelectedApps()) {
			<div class="apps-section__actions">
				<csd-app-button classList="w-28" routerLink="../../">Cancel</csd-app-button>
				<csd-app-button
					type="tonal"
					classList="w-auto"
					(click)="onSaveAndPush()"
					[disabled]="isLoading()"
				>
					Save and push
				</csd-app-button>
				<csd-app-button
					variation="secondary"
					classList="w-28"
					htmlType="submit"
					[disabled]="isLoading()"
				>
					Save
					@if (isLoading()) {
						<csd-app-loading-icon />
					}
				</csd-app-button>
			</div>
		}
	</div>
</form>
