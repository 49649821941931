<div class="policy-details">
	<h2 class="policy-details__title">New Android Policy</h2>
	<form [formGroup]="form" class="policy-details__form">
		<ng-template #dynamicComponentContainer></ng-template>

		<div class="policy-details__actions">
			<a routerLink="../" class="hover:text-layout-stone-gray"
				><csd-app-button classList="w-28">Cancel</csd-app-button></a
			>
			@if (!isFirstSection) {
				<csd-app-button classList="w-28" type="tonal" (click)="onBack(selectedComponent())">
					Back
				</csd-app-button>
			}
			<csd-app-button
				classList="w-28"
				variation="secondary"
				(click)="onNext(selectedComponent())"
				[disabled]="isLoading()"
			>
				{{ isLastSection ? 'Save' : 'Next' }}
				@if (isLoading()) {
					<csd-app-loading-icon />
				}
			</csd-app-button>
		</div>
	</form>
</div>
