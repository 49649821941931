<div class="customer-info" [class.animate-pulse]="!userName()">
	<csd-app-user-image classList="ml-4 mr-4 w-10 h-10" [image]="userImg()" [user]="userName()" />
	<div class="customer-info__data">
		@if (userName()) {
			<p class="text-base text-layout-stone-gray font-bold truncate">{{ userName() }}</p>
			<p class="text-xs text-layout-quicksilver truncate">{{ customer()?.streetAddress || '' }}</p>
		} @else {
			<div class="h-3 bg-layout-stone-gray rounded w-[60%] mb-2 opacity-30"></div>
			<div class="h-2 bg-layout-quicksilver rounded w-[40%] opacity-30"></div>
		}
	</div>
	<div class="search-area">
		<p class="m-0 pl-5 flex items-center justify-center">
			<button class="text-2xl leading-7" (click)="removeSelection()">
				<mat-icon inline class="cancel-icon rounded-full align-top">cancel</mat-icon>
			</button>
		</p>
	</div>
</div>
