<div class="app-item bg-layout-sky rounded flex justify-between items-center pr-3">
	<mat-checkbox class="app-item__checkbox" [checked]="checked()" (change)="update($event.checked)">
		{{ text() }}
	</mat-checkbox>
	@if (showReset()) {
		<mat-icon
			class="material-symbols-filled text-layout-shingle-gray"
			[mtxPopoverTriggerFor]="popover"
			mtxPopoverTriggerOn="hover"
		>
			info
		</mat-icon>

		<mtx-popover #popover="mtxPopover" [closeOnBackdropClick]="true" [hasBackdrop]="true">
			<p class="flex items-center flex-wrap max-w-36 xs:max-w-96 gap-3 justify-center text-center">
				Configuration defers from group policy
				<button
					class="text-error-secondary rounded-xl bg-error-primary px-3"
					matRipple
					(click)="reset.emit()"
				>
					Reset
				</button>
			</p>
		</mtx-popover>
	}
</div>
