import { NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';

@Component({
	selector: 'csd-app-user-image',
	standalone: true,
	imports: [NgClass],
	templateUrl: './user-image.component.html',
	styleUrl: './user-image.component.scss',
})
export class UserImageComponent {
	classList = input<string>('');
	image = input<string>('');
	user = input<string>('');

	userInitials = computed(() =>
		this.user()
			?.split(' ')
			.map((el) => el[0])
			?.slice(0, 3)
			?.join('')
			?.toUpperCase(),
	);
}
