import { ChangeDetectionStrategy, Component, inject, input, output, signal } from '@angular/core';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'csd-app-uninstall-key',
	standalone: true,
	imports: [MatIconModule, ReactiveFormsModule],
	templateUrl: './uninstall-key.component.html',
	styleUrl: './uninstall-key.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UninstallKeyComponent {
	#rootFormGroup = inject(FormGroupDirective);

	text = input.required<string>();
	changed = output<void>();
	isEditing = signal<boolean>(false);

	get form() {
		return this.#rootFormGroup.control;
	}

	cancel() {
		this.form.get('uninstallKey')?.setValue(this.text());
		this.isEditing.set(false);
	}

	confirmChange() {
		this.isEditing.set(false);
		this.changed.emit();
	}

	onKey(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			this.confirmChange();
		}
	}

	toggleText(text: string = '') {
		return this.isEditing() ? text : '••••••••••••••';
	}
}
