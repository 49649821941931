import { Routes } from '@angular/router';

import { OverviewComponent } from './overview/overview.component';

export default <Routes>[
	{
		path: '',
		component: OverviewComponent,
		data: { breadcrumb: '' },
	},
];
