<ng-container>
	@if (totalSelectedApps() || appsList.length) {
		<div class="add-app-section">
			<div class="add-app-section__search w-full md:max-w-48 relative">
				<input
					class="w-full pr-8"
					(keyup)="searchApp($event)"
					placeholder="Search apps"
					#searchInput
					matInput
				/>
				@if (searchAppTerm()) {
					<button
						type="button"
						class="absolute right-1 top-0 text-layout-quicksilver text-xl leading-relaxed"
						(click)="clearSearch()"
					>
						<mat-icon inline class="align-text-top">cancel</mat-icon>
					</button>
				}
			</div>
			<button class="add-app-section__button" (click)="openAddAppModal()" type="button">
				Add app restrictions <mat-icon inline class="align-bottom">arrow_right_alt</mat-icon>
			</button>
		</div>

		<table mat-table [dataSource]="dataSource()">
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef>Name</th>
				<td mat-cell *matCellDef="let element">{{ element.appResponse?.name }}</td>
			</ng-container>

			<ng-container matColumnDef="package">
				<th mat-header-cell *matHeaderCellDef>Package</th>
				<td mat-cell *matCellDef="let element" class="break-all">
					{{ element.appResponse?.packageName }}
				</td>
			</ng-container>

			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef>Status</th>
				<td mat-cell *matCellDef="let element">{{ getAppStatus(element.status) }}</td>
			</ng-container>

			<ng-container matColumnDef="vpn">
				<th mat-header-cell *matHeaderCellDef>VPN</th>
				<td mat-cell *matCellDef="let element">{{ getVpnStatus(element.internetConfig) }}</td>
			</ng-container>

			<ng-container matColumnDef="delete">
				<th mat-header-cell *matHeaderCellDef>Remove</th>
				<td mat-cell *matCellDef="let element; let i = index">
					<csd-app-button type="text" (click)="removeAppFromList(i)">
						<mat-icon inline class="align-bottom">cancel</mat-icon>
					</csd-app-button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			<tr class="mat-row" *matNoDataRow>
				<td
					class="mat-cell py-10 text-center text-layout-stone-gray"
					[attr.colspan]="displayedColumns.length"
				>
					<span class="cursor-pointer underline decoration-1" (click)="clearSearch()"
						>Clear your filters</span
					>
					to see all available data.
				</td>
			</tr>
		</table>
	} @else {
		<csd-app-empty-state icon="device_unknown">
			<div>This policy is currently empty.</div>
			<ng-content empty-state-message>
				<p>Add apps to start managing their permissions.</p>
			</ng-content>
			<ng-content empty-state-actions>
				<button class="add-app-section__button" (click)="openAddAppModal()" type="button">
					Add app restrictions <mat-icon inline class="align-bottom">arrow_right_alt</mat-icon>
				</button>
			</ng-content>
		</csd-app-empty-state>
	}
</ng-container>
