@if (isLoading()) {
  <div class="w-full">
    <img class="mx-auto w-32 lg:w-40" src="../../../../assets/circle-loader.gif" alt="loading...please wait" />
  </div>
} @else {
  <form [formGroup]="form">
    <div class="w-full pt-12">
      <div class="space-y-3 mb-8 md:w-96 mx-auto">
        <div class="p-4">
          <div class="flex justify-center">
            <div class="space-x-2 ">
              <div class="mx-2 sm:invisible">
                <p class="text-sm md:text-base text-slate-400">Enter code</p>
              </div>
              <ng-container formArrayName="code">
                @for (item of codes; track item; let index = $index) {
                  <input
                    class="code-input w-10 h-10 sm:w-12 sm:h-12 text-center text-xl border-2 border-gray-300 rounded-lg focus:outline-none focus:border-teal-600"
                    type="text"
                    inputmode="numeric"
                    maxlength="1"
                    [formControlName]="index"
                    (keyup)="codeKeyup($event)"
                  />
                }
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <csd-app-actions
      cancelLabel="&#8592; Back"
      continueLabel="Request new code"
      [continueDisabled]="!canRequestNewCode()"
      (canceled)="stepBack()"
      (submitted)="requestCode()"
    >
      <div extra-info>
        Request new code in <countdown [config]="countdownConfig" (event)="countdownFinished($event)"></countdown>
      </div>
    </csd-app-actions>
  </form>
}
