import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	OnDestroy,
	signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';
import { AppOptions } from '@root/app/shared/apps.types';
import { AppItemComponent } from '@ui/app-item/app-item.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, finalize, Subscription } from 'rxjs';

import { DeviceDetailsService } from '../../device-details/device-details.service';
import { DevicesService } from '../../devices/devices.service';
import { DevicesStore } from '../../devices/devices.store';
import { AppsService, DeviceAppsUpdate } from './apps.service';

@Component({
	selector: 'csd-app-apps',
	standalone: true,
	imports: [
		AppItemComponent,
		EmptyStateComponent,
		FormsModule,
		HeaderDividerComponent,
		LoaderComponent,
		LoadingIconComponent,
		MatIconModule,
		MatInputModule,
		MatSelectModule,
	],
	templateUrl: './apps.component.html',
	styleUrl: './apps.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsComponent implements OnDestroy {
	readonly #devicesStore = inject(DevicesStore);
	#appsService = inject(AppsService);
	#deviceDetailsService = inject(DeviceDetailsService);
	#devicesService = inject(DevicesService);
	#notificationService = inject(NotificationService);

	appOptions = this.#devicesService.appOptions;
	device = this.#devicesStore.selectedDevice;
	isLoading = this.#deviceDetailsService.isLoading;
	isResetingConfig = signal<boolean>(false);
	launcherFilterApps = signal<AndroidAppConfig[]>([]);
	systemFilterApps = signal<AndroidAppConfig[]>([]);
	uninstalledFilterApps = signal<AndroidAppConfig[]>([]);

	searchText = '';
	subscriptions: Subscription[] = [];

	launcherApps = computed(() => {
		const { appTypes = {} } = this.appOptions() as AppOptions;
		const normalAppsId = Object.values(appTypes).findIndex((el) => el === 'Launcher');

		return this.device().policy.policyAppJoins.filter(
			(el) => el?.appResponse?.type === normalAppsId,
		);
	});

	systemApps = computed(() => {
		const { appTypes = {} } = this.appOptions() as AppOptions;
		const systemAppsId = Object.values(appTypes).findIndex((el) => el === 'System');

		return this.device().policy.policyAppJoins.filter(
			(el) => el?.appResponse?.type === systemAppsId,
		);
	});

	constructor() {
		effect(this.updateComponentData.bind(this), { allowSignalWrites: true });
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	clearSearch() {
		this.searchText = '';
		this.filterApps(this.searchText);
	}

	filterApps(value: string) {
		let launcherFilterApps = this.launcherApps();
		let systemFilterApps = this.systemApps();

		if (value) {
			launcherFilterApps = this.launcherApps().filter((item) => this.filterPackage(item, value));
			systemFilterApps = this.systemApps().filter((item) => this.filterPackage(item, value));
		}

		this.launcherFilterApps.set(launcherFilterApps);
		this.systemFilterApps.set(systemFilterApps);
	}

	filterPackage(item: AndroidAppConfig, value: string) {
		const matchRegex = new RegExp(value, 'gi');
		const { appResponse } = item;

		return appResponse?.name?.match(matchRegex) || appResponse?.packageName?.match(matchRegex);
	}

	updateComponentData() {
		if (!this.device()) {
			return;
		}

		this.launcherFilterApps.set(this.launcherApps());
		this.systemFilterApps.set(this.systemApps());
	}

	updateStatus(newAppData: DeviceAppsUpdate, app: AndroidAppConfig) {
		const updateSub$ = this.#appsService
			.updateStatus(this.device().id, newAppData)
			.pipe(
				catchError((err) => {
					throw new Error(err.error?.title || err.message);
				}),
			)
			.subscribe(() => {
				const { appId, internetConfig, status } = newAppData;
				const dataToUpdateLocally = { status, internetConfig };
				this.updateAppInStore(appId, dataToUpdateLocally);
				this.#notificationService.openSuccess({
					message: `App policy for <strong>${app.appResponse.name}</strong> was updated`,
				});
			});

		this.subscriptions.push(updateSub$);
	}

	resetAppConfig(app: AndroidAppConfig) {
		const deviceId = this.device().id;

		this.#notificationService.open({
			message: `Resetting config for <strong>${app.appResponse.name}</strong>...`,
		});

		const sub$ = this.#appsService
			.removeAppValues(deviceId, app.appId)
			.pipe(finalize(() => this.isResetingConfig.set(false)))
			.subscribe(() => {
				const appName = app.appResponse.name;
				this.updateAppInStore(app.appId, { differentThanGroup: false });
				this.#notificationService.openSuccess({
					message: `Config for app ${appName} was reset successfully`,
				});
			});

		this.subscriptions.push(sub$);
	}

	updateAppInStore(appId: string, dataToUpdateLocally: Partial<AndroidAppConfig>) {
		const newAppsList = this.device().policy.policyAppJoins.map((app) => {
			if (app.appId === appId) {
				return { ...app, ...dataToUpdateLocally };
			}

			return app;
		});
		this.#devicesStore.updateDeviceDetail(this.device().id, {
			policy: { ...this.device().policy, policyAppJoins: newAppsList },
		});
	}
}
