@if (isLoading()) {
	<div class="mx-auto min-h-screen w-52 content-center align-middle lg:w-96">
		<p><img src="../../../../assets/logo-emypeople.png" alt="eMyPeople logo" /></p>
		<p class="text-center text-xl font-bold">
			<csd-app-animate-ellipsis>{{ statusText() }}</csd-app-animate-ellipsis>
		</p>
	</div>
} @else {
	<ng-content></ng-content>
}
