export const RECURRING_DAYS = [
	{
		label: '1 day',
		value: 1,
	},
	...new Array(9).fill(null).map((_, i) => ({
		label: `${i + 2} days`,
		value: i + 2,
	})),
];

export const DAYS_ALERTED = [...RECURRING_DAYS];
