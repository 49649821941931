import { Routes } from '@angular/router';

import { CustomersComponent } from './customers.component';

export default <Routes>[
	{
		path: '',
		component: CustomersComponent,
	},
];
