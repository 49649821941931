import { ErrorHandler, inject } from '@angular/core';
import { NotificationService } from '@ui/notification/notification.service';

export class MyErrorHandler implements ErrorHandler {
	notificationService = inject(NotificationService);

	handleError(error: Error | any) {
		this.notificationService.openError({
			message: error?.message,
		});

		console.error(error);
	}
}
