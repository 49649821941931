import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'csd-app-tag',
	standalone: true,
	imports: [NgClass],
	templateUrl: './tag.component.html',
	styleUrl: './tag.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
	classList = input<string[] | string>('');
}
