import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';

@Component({
	selector: 'csd-app-app-checkbox',
	standalone: true,
	imports: [MatCheckboxModule, MatIconModule, MatRippleModule, MtxPopoverModule],
	templateUrl: './app-checkbox.component.html',
	styleUrl: './app-checkbox.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppCheckboxComponent {
	text = input.required<string>();
	checked = input<boolean>(false);
	showReset = input<boolean>(false);

	change = output<boolean>();
	reset = output<void>();

	update(value: boolean) {
		this.change.emit(value);
	}
}
