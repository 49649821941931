import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivationStart, Event, Router, RouterOutlet } from '@angular/router';
import { filter, finalize, Subscription } from 'rxjs';

import { ActivityService } from './core/activity/activity.service';
import { AuthService } from './core/auth/auth.service';
import { CustomersStore } from './feature/customers/customers.store';
import { DevicesService } from './feature/devices/devices.service';
import { LoaderFullScreenComponent } from './ui/loader-full-screen/loader-full-screen.component';

@Component({
	selector: 'csd-app-root',
	standalone: true,
	imports: [LoaderFullScreenComponent, RouterOutlet],
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
	readonly #store = inject(CustomersStore);
	#activityService = inject(ActivityService);
	#authService = inject(AuthService);
	#devicesService = inject(DevicesService);
	#matIconReg = inject(MatIconRegistry);
	#router = inject(Router);

	title = 'Control Stack Dashboard';
	subscriptions: Subscription[] = [];
	isLoading = signal<boolean>(false);

	ngOnInit(): void {
		this.isLoading.set(true);

		const routerSub$ = this.#router.events.subscribe(this.validatePublicRoute.bind(this));
		const authSub$ = this.#authService
			.checkAuth()
			.pipe(
				filter((data) => data?.isAuthenticated),
				finalize(() => this.isLoading.set(false)),
			)
			.subscribe(this.loadConfig.bind(this));

		this.#matIconReg.setDefaultFontSetClass('material-symbols-outlined');
		this.#activityService.addListeners();
		this.subscriptions.push(authSub$, routerSub$);
	}

	ngOnDestroy(): void {
		this.#activityService.removeListeners();
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	loadConfig() {
		const options$ = this.#devicesService.getAppOptions().subscribe();
		this.subscriptions.push(options$);
	}

	validatePublicRoute(event: Event) {
		if (!(event instanceof ActivationStart)) {
			return;
		}

		const customerId = event.snapshot.params['customerId'];
		const oldCustomer = this.#store.selectedCustomerId();
		const isSameCustomer = decodeURIComponent(customerId) && oldCustomer;
		const isPublicCustomer = customerId?.toLowerCase() === 'public';

		if (customerId && !isSameCustomer && !isPublicCustomer) {
			this.#store.updateSelectedCustomer(customerId);
		}
	}
}
