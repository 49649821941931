import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { AnimateEllipsisComponent } from '../animate-ellipsis/animate-ellipsis.component';

@Component({
	selector: 'csd-app-loader-full-screen',
	standalone: true,
	imports: [AnimateEllipsisComponent],
	templateUrl: './loader-full-screen.component.html',
	styleUrl: './loader-full-screen.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderFullScreenComponent {
	isLoading = input<boolean>(false);
	statusText = input<string>('Loading');
}
