import { Routes } from '@angular/router';
import { PolicyLayoutComponent } from '@layout/policy-layout/policy-layout.component';

import { PolicyAddComponent } from '../policy-add/policy-add.component';
import { policyDetailsResolver } from '../policy-details/policy-details.resolver';
import policyDetailsRoutes from '../policy-details/policy-details.routes';
import { PoliciesComponent } from './policies.component';

const baseTitle = 'Policies | eMyPeople';

export default <Routes>[
	{
		path: '',
		component: PoliciesComponent,
		title: baseTitle,
		data: { breadcrumb: '' },
	},
	{
		path: 'add-policy',
		component: PolicyAddComponent,
		title: `Add Policy | ${baseTitle}`,
		data: { breadcrumb: 'Add Policy' },
	},
	{
		path: ':groupId',
		component: PolicyLayoutComponent,
		resolve: { group: policyDetailsResolver },
		data: { breadcrumb: (data: any) => data?.[0]?.group?.name },
		children: policyDetailsRoutes,
	},
];
