<div class="relative flex">
  <div class="w-full md:w-1/2">
    <div class="min-h-screen h-full flex flex-col">
      <div class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
        <a
          href="https://emypeople.net"
          class="group inline-flex items-center rounded-full bg-slate-600 px-4 py-1.5 font-semibold text-slate-200 transition hover:text-slate-100"
        >
          <svg class="mt-0.5 mr-3 rotate-180 stroke-white stroke-2" fill="none" width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
            <path class="opacity-0 transition group-hover:opacity-100" d="M0 5h7"></path>
            <path class="transition group-hover:translate-x-[3px]" d="M1 1l4 4-4 4"></path>
          </svg>
          Back to Website
        </a>
      </div>
      <div class="flex flex-col justify-center grow">
        <div class="max-w-sm mx-auto px-4 py-8">
          <div class="grid place-content-center">
            <img src="../../../../assets/logo-emypeople.png" alt="eMyPeople logo" class="place-content-center hidden md:block mb-20" role="presentation" />
          </div>
          <div class="grid place-content-center">
            <h1 class="text-4xl w-full text-justify text-sky-900 font-bold mb-6">Welcome back!</h1>
          </div>
          <button class="w-full p-2 bg-emerald-800 hover:bg-emerald-900 rounded-lg place-content-center disabled:opacity-75" (click)="login()" [disabled]="isLoading">
            <span class="text-white">
              @if (isLoading) {
                Logging in...
              } @else {
                Continue with eMyPeople ID
              }
            </span>
          </button>
          <div class="pt-5 mt-6 border-t border-slate-200">
            <div class="text-sm text-center">
              Don't have an account yet?
              <a class="font-medium text-indigo-500 hover:text-indigo-600" href="/signup">
                Sign Up
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
    <img
      class="object-cover object-center w-full h-full"
      src="../../../../assets/auth-image.jpg"
      alt="Authentication"
      role="presentation"
    />
  </div>
</div>
