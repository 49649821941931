import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	input,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import {
	ActivatedRoute,
	Event,
	NavigationEnd,
	Router,
	RouterOutlet,
	Scroll,
} from '@angular/router';
import { CustomersStore } from '@feature/customers/customers.store';
import { DeviceDetailsService } from '@feature/device-details/device-details.service';
import { DevicesStore } from '@feature/devices/devices.store';
import { BannerComponent } from '@ui/banner/banner.component';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService } from '@ui/notification/notification.service';
import { SectionMenuComponent, SectionMenuItem } from '@ui/section-menu/section-menu.component';
import { catchError, filter, Subscription, tap } from 'rxjs';

import { DEVICES_LINKS, ROUTES_WITH_TIMERS } from './device-layout.constants';

@Component({
	selector: 'csd-app-device-layout',
	standalone: true,
	imports: [
		BannerComponent,
		ButtonComponent,
		DatePipe,
		LoadingIconComponent,
		RouterOutlet,
		SectionMenuComponent,
	],
	templateUrl: './device-layout.component.html',
	styleUrl: './device-layout.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceLayoutComponent implements OnInit, OnDestroy {
	readonly #customersStore = inject(CustomersStore);
	readonly #devicesStore = inject(DevicesStore);
	#activatedRoute = inject(ActivatedRoute);
	#deviceDetailsService = inject(DeviceDetailsService);
	#notificationService = inject(NotificationService);
	#router = inject(Router);

	deviceId = input<string>('');
	device = this.#devicesStore.selectedDevice;
	isRemovingTimer = signal<boolean>(false);
	currentSection = signal<string>('');
	subscriptions: Subscription[] = [];
	shouldDisplayBanner = signal<boolean>(false);

	devicesLinks = computed<SectionMenuItem[]>(() =>
		DEVICES_LINKS.map((el) => {
			const { overrideFinishedUtc } = this.device() ?? {};
			const customerCode = this.#customersStore.selectedCustomer()?.code ?? '';
			const route = `/${customerCode}/devices/${this.deviceId()}/${el.route}`;

			return {
				...el,
				route,
				active: this.currentSection() === el.route,
				timer: !!(ROUTES_WITH_TIMERS.includes(el.route) && overrideFinishedUtc),
			};
		}),
	);

	ngOnInit(): void {
		const routeSub$ = this.#router.events
			.pipe(filter(this.isNavigationEndEvent.bind(this)))
			.subscribe(this.updateSectionData.bind(this));

		this.subscriptions.push(routeSub$);
		this.updateSectionData();
		this.validateTimerStatus();
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	closeDevice() {
		this.isRemovingTimer.set(true);
		const closeSub$ = this.#deviceDetailsService
			.removeTimer(this.device().id)
			.pipe(
				tap(() => this.isRemovingTimer.set(false)),
				catchError(() => {
					this.isRemovingTimer.set(false);
					throw new Error(
						`<p>Unable to remove temporary timer.</p>
            <p>Please check your device settings and try again.</p>`,
					);
				}),
			)
			.subscribe(this.timerRemoved.bind(this));

		this.subscriptions.push(closeSub$);
	}

	isNavigationEndEvent(event: Event) {
		const isNavigationEnd = event instanceof NavigationEnd;
		const isScroll = event instanceof Scroll;

		return isNavigationEnd || (isScroll && event.routerEvent instanceof NavigationEnd);
	}

	validateTimerStatus() {
		const { overrideFinishedUtc } = this.device();
		const currentPath = this.#activatedRoute.snapshot.firstChild?.url[0].path;
		const isTimerRoute = ROUTES_WITH_TIMERS.includes(currentPath || '');

		this.shouldDisplayBanner.set(isTimerRoute && !!overrideFinishedUtc);
	}

	updateSectionData() {
		const urlLastSection = this.#router.url.split('/').pop();

		this.validateTimerStatus();
		this.currentSection.set(urlLastSection?.replace(/(\?|\#).*/gi, '') as string);
	}

	timerRemoved() {
		this.#notificationService.openSuccess({
			message: `Removed temporary timer for <strong>${this.device().name}</strong>.`,
		});

		this.shouldDisplayBanner.set(false);
		this.#devicesStore.updateDeviceDetail(this.device().id, { overrideFinishedUtc: undefined });
	}
}
