<ng-container>
  <csd-app-loader [isLoading]="isLoading()">
    <div class="pt-6 2xl:pt-12 px-4 2xl:max-w-full m-auto">
      <form [formGroup]="form">
        <div class="mb-4 space-y-3 min-h-48 2xl:mb-8">
          @for (numberEnding of numbersList(); track numberEnding; let index = $index) {
            <label class="relative block cursor-pointer">
              <input
                type="radio"
                class="sr-only peer"
                [value]="'call-' + numberEnding"
                formControlName="method"
                />
              <div
                class="flex w-full items-center rounded-lg border border-slate-200 bg-white p-4 text-base font-medium text-slate-800 shadow-sm duration-150 ease-in-out hover:border-slate-300"
              >
                <div class="px-5 text-slate-700">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"/>
                  </svg>
                </div>
                <span>Receive a call at...</span>
                <span class="ml-auto font-semibold">*** *** **{{ numberEnding }}</span>
              </div>
              <div
                class="pointer-events-none absolute inset-0 rounded-lg border-2 border-transparent peer-checked:border-teal-800"
                aria-hidden="true">
              </div>
            </label>
            <label class="relative block cursor-pointer">
              <input
                type="radio"
                class="sr-only peer"
                [value]="'text-' + numberEnding"
                formControlName="method"
              />
              <div
                class="flex w-full items-center rounded-lg border border-slate-200 bg-white p-4 text-base font-medium text-slate-800 shadow-sm duration-150 ease-in-out hover:border-slate-300">
                <div class="px-5 text-slate-700">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"/>
                  </svg>
                </div>
                <span>Text a code to...</span>
                <span class="ml-auto font-semibold">*** *** **{{ numberEnding }}</span>
              </div>
              <div
                class="pointer-events-none absolute inset-0 rounded-lg border-2 border-transparent peer-checked:border-teal-800"
                aria-hidden="true">
              </div>
            </label>
          }
        </div>
      </form>
    </div>

    <csd-app-actions
      cancelLabel="&#8592; Back "
      continueLabel="Send Now"
      [continueDisabled]="!isOptionsSelected"
      (canceled)="stepBack()"
      (submitted)="requestCode()"
    ></csd-app-actions>
  </csd-app-loader>
</ng-container>
