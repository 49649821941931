import { NgClass } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { DeviceAppsUpdate } from '@feature/device-details/apps/apps.service';
import { MtxPopoverModule, MtxPopoverPosition } from '@ng-matero/extensions/popover';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';

import { ACTIVE_OPTIONS, VPN_CONFIG_OPTIONS } from './app-item.constants';
import { AppPolicyChangeOptions } from './app-item.types';

@Component({
	selector: 'csd-app-app-item',
	standalone: true,
	imports: [MatIconModule, MatRippleModule, MatSelectModule, MtxPopoverModule, NgClass],
	templateUrl: './app-item.component.html',
	styleUrl: './app-item.component.scss',
})
export class AppItemComponent {
	activeOptions = ACTIVE_OPTIONS;
	position: MtxPopoverPosition = ['above', 'after'];
	vpnConfigOptions = VPN_CONFIG_OPTIONS;

	appsStatus = input<Record<string, string>>({});
	data = input<AndroidAppConfig>();
	classList = input<string | string[]>('');
	vpnOn = input<boolean>(false);
	changedConfig = output<DeviceAppsUpdate>();
	reset = output<void>();

	appStatusFromData = computed(() => {
		const availableOptions = ACTIVE_OPTIONS.map((el) => el.value);
		const defaultStatus = ACTIVE_OPTIONS[0].value;
		const appStatus = this.data()?.status || defaultStatus;
		return availableOptions.includes(appStatus) ? appStatus : defaultStatus;
	});
	appStatusOptions = computed(() => {
		return Object.keys(this.appsStatus()).map((el) => ({
			label: (this.appsStatus() as unknown as Record<string, string>)?.[el],
			value: el,
		}));
	});

	compareSimpleValues(a: string, b: string) {
		return a == b;
	}

	getLogo(link?: string) {
		return link ?? '../../../assets/android-default-app-icon.png';
	}

	onSelectChange(policy: AppPolicyChangeOptions, value: number) {
		if (!this.data()) {
			return;
		}

		const data = this.data() as AndroidAppConfig;
		const dataToEmit = {
			appId: data.appId,
			packageName: data.appResponse.packageName,
			status: Number(data.status),
			internetConfig: Number(data.internetConfig),
		};

		this.changedConfig.emit({ ...dataToEmit, [policy]: Number(value) });
	}
}
