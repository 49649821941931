import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'csd-app-empty-state',
	standalone: true,
	imports: [MatIconModule],
	templateUrl: './empty-state.component.html',
	styleUrl: './empty-state.component.scss',
})
export class EmptyStateComponent {
	icon = input('person_alert');
	onlyText = input(false);
}
