import { Component } from '@angular/core';

@Component({
	selector: 'csd-app-animate-ellipsis',
	standalone: true,
	imports: [],
	templateUrl: './animate-ellipsis.component.html',
	styleUrl: './animate-ellipsis.component.scss',
})
export class AnimateEllipsisComponent {}
