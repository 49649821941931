<div class="flex flex-col items-center px-4 md:px-4">
  <h1 class="text-3xl md:text-4xl 2xl:text-6xl text-teal-800 font-semibold">Let's Get Started</h1>
  <div class="md:px-12">
    <h5 class="lg:text-xl 2xl:text-2xl py-5">
      You must be an existing eMyPeople customer to access MyAccount. Contact our sales team to get you started
      with one or more of our services.
    </h5>
    <div class="font-semibold">
      <div class="flex">
        <div class="my-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"></path>
          </svg>
        </div>
        <a href="tel:7172770200" class="p-2">717.277.0200</a>
      </div>
      <div class="flex">
        <div class="my-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"></path>
          </svg>
        </div>
        <a href="mailto:sales@emypeople.net" class="p-2">sales&commat;emypeople.net</a>
      </div>
    </div>

    <div class="py-8">
      <span>
        Already a customer but still seeing this?
        <a class="text-teal-800 underline font-bold" href="https://docs.emypeople.net" target="_blank">Contact</a>
        our support team and we will get you connected.
      </span>
    </div>
    <div class="pt-4">
      <div class="flex flex-col-reverse justify-between md:flex-row">
        <button
          class="h-12 w-full rounded-lg border-2 border-teal-800 p-2 md:mt-0 md:w-32"
          (click)="logout()"
        >
          Log Out
        </button>
        <a
          class="text-teal-800 p-2 after:content-['_↗']"
          href="https://docs.emypeople.net"
          target="_blank">
          FAQ Documents
        </a>
      </div>
    </div>
  </div>
</div>
