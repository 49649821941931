import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	private isRefreshingToken = false;
	private tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error) => {
				if (error instanceof HttpErrorResponse && error.status === 401) {
					return this.handle401Error(request, next);
				}
				throw new Error(error);
			}),
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;
			this.tokenSubject.next(null);

			return this.authService.refreshToken().pipe(
				switchMap((token) => {
					this.isRefreshingToken = false;
					this.tokenSubject.next(token.accessToken);
					return next.handle(this.addTokenHeader(request, token.accessToken));
				}),
				catchError((err) => {
					this.isRefreshingToken = false;
					this.tokenSubject.next(null);
					this.authService.logout();
					throw new Error(err);
				}),
				finalize(() => {
					this.isRefreshingToken = false;
				}),
			);
		} else {
			return this.tokenSubject.pipe(
				filter((token) => token !== null),
				take(1),
				switchMap((token) => {
					return next.handle(this.addTokenHeader(request, token!));
				}),
			);
		}
	}

	private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
}
