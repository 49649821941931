import { ChangeDetectionStrategy, Component, inject, input, output, signal } from '@angular/core';
import { FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
	selector: 'csd-app-name',
	standalone: true,
	imports: [MatInputModule, MatIconModule, ReactiveFormsModule],
	templateUrl: './device-name.component.html',
	styleUrl: './device-name.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceNameComponent {
	#rootFormGroup = inject(FormGroupDirective);

	text = input.required<string>();
	isEditing = signal<boolean>(false);
	changed = output<void>();

	get form() {
		return this.#rootFormGroup.control;
	}

	onCancel() {
		this.isEditing.set(false);
		this.form.get('name')?.setValue(this.text());
	}

	onKey(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			this.onNameChange();
		}
	}

	onNameChange() {
		this.isEditing.set(false);
		this.changed.emit();
	}
}
