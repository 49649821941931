import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { PoliciesService } from '@feature/policies/policies.service';
import { ButtonComponent } from '@ui/button/button.component';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, Subscription, tap } from 'rxjs';

import { CustomersStore } from '../../customers/customers.store';
import { DeviceDetailsService } from '../../device-details/device-details.service';
import { DevicesStore } from '../../devices/devices.store';
import { GroupFromList } from '../../policies/policies.types';
import { DeviceNameComponent } from './device-name/device-name.component';
import { ANDROID_COMMANDS, COMMAND_BUTTONS, UPDATES_CHANNELS } from './stats.constants';
import { UninstallKeyComponent } from './uninstall-key/uninstall-key.component';

@Component({
	selector: 'csd-app-stats',
	standalone: true,
	imports: [
		ButtonComponent,
		DatePipe,
		DeviceNameComponent,
		HeaderDividerComponent,
		MatIconModule,
		MatOptionModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		ReactiveFormsModule,
		UninstallKeyComponent,
	],
	templateUrl: './stats.component.html',
	styleUrl: './stats.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsComponent implements OnInit, OnDestroy {
	readonly #customersStore = inject(CustomersStore);
	readonly #devicesStore = inject(DevicesStore);
	#deviceDetailsService = inject(DeviceDetailsService);
	#fb = inject(FormBuilder);
	#notificationService = inject(NotificationService);
	#policiesService = inject(PoliciesService);

	device = this.#devicesStore.selectedDevice;
	selectedWorkOrder = this.#devicesStore.selectedSubscription;

	commandButtons = COMMAND_BUTTONS;
	updatesChannels = UPDATES_CHANNELS;
	form = this.#fb.group({
		name: this.device().name,
		releaseChannel: this.device().releaseChannel,
		group: this.device().group.id || '',
		uninstallKey: this.device().uninstallKey || '',
	});
	groups = signal<GroupFromList[]>([]);
	loadingCmdUpdates = signal(false);
	subscriptions: Subscription[] = [];

	get baseDataToUpdate() {
		return {
			name: this.device().name,
			group: this.device().group['id'] as string,
			uninstallKey: this.device().uninstallKey,
			releaseChannel: this.device().releaseChannel,
			workOrder: this.device().workOrder || this.selectedWorkOrder(),
		};
	}

	ngOnInit(): void {
		const customer$ = this.#policiesService.getByCustomer(
			this.#customersStore.selectedCustomerId() as string,
		);
		const pipe$ = customer$.pipe(
			catchError(() => {
				this.form.get('group')?.disable();
				return [];
			}),
		);
		const sub$ = pipe$.subscribe((groups) => {
			const groupsList = groups?.allPolicies || [];

			this.groups.set(groupsList);

			if (groupsList && groupsList.length === 0) {
				this.form.get('group')?.disable();
			}
		});

		this.subscriptions.push(sub$);
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	commandDevice(command: string) {
		const commandNumber = ANDROID_COMMANDS[command];
		const commandLabel = this.commandButtons.find((c) => c.command === command)?.label;
		const deviceId = this.device().id;

		this.loadingCmdUpdates.set(true);

		const commandSub$ = this.#deviceDetailsService
			.sendCommand(deviceId, commandNumber)
			.pipe(
				tap(() => this.loadingCmdUpdates.set(false)),
				catchError(() => {
					this.loadingCmdUpdates.set(false);
					throw new Error(`Error updating device with command: ${commandLabel}`);
				}),
			)
			.subscribe(() => {
				this.#notificationService.openSuccess({
					message: `Successfully applied command <strong>${commandLabel}</strong> to the device`,
				});
			});

		this.subscriptions.push(commandSub$);
	}

	sendCommand(command: string) {
		const deviceId = this.device().id;

		if (!deviceId) {
			throw new Error('No device id found');
		}

		this.commandDevice(command);
	}

	updateDevice(field: string) {
		const fieldValue = this.form.get(field)?.value;

		if (!fieldValue) {
			return;
		}

		const data = {
			...this.baseDataToUpdate,
			[field]: fieldValue,
		};

		const updateSub$ = this.#deviceDetailsService
			.updateDevice(this.device().id, data)
			.subscribe(() => {
				this.#notificationService.openSuccess({
					message: `<strong>${field}</strong> was updated.`,
				});
				this.#devicesStore.updateDeviceDetail(this.device().id, {
					name: data.name,
					group: this.groups().find((group) => group.id === data.group),
					uninstallKey: data.uninstallKey,
					releaseChannel: data.releaseChannel,
					workOrder: data.workOrder,
				});
			});

		this.subscriptions.push(updateSub$);
	}

	selectCompareValue(first: string | number, second: string | number) {
		if (Number.isNaN(+first) || Number.isNaN(+second)) {
			return first === second;
		}

		return +first === +second;
	}
}
