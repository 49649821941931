import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { AndroidAppConfig } from '@root/app/shared/android-apps.types';
import { AppsListComponent } from '@ui/apps-list/apps-list.component';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, finalize, Subscription } from 'rxjs';

import { PolicyDetailsService } from './../policy-details.service';

@Component({
	selector: 'csd-app-apps',
	standalone: true,
	imports: [
		AppsListComponent,
		ButtonComponent,
		LoadingIconComponent,
		MatIconModule,
		ReactiveFormsModule,
		RouterLink,
	],
	templateUrl: './apps.component.html',
	styleUrl: './apps.component.scss',
})
export class AppsComponent implements OnInit, OnDestroy {
	fb = inject(FormBuilder);
	notificationService = inject(NotificationService);
	policyDetailsService = inject(PolicyDetailsService);

	currentGroup = this.policyDetailsService.currentGroup;
	form = this.fb.group({
		apps: [[...(this.currentGroup()?.policy?.policyAppJoins || [])], [Validators.minLength(1)]],
	});
	isLoading = signal(false);
	subscriptions: Subscription[] = [];
	totalSelectedApps = signal<number>(this.appsList.length);

	get appsList() {
		return (this.form.controls['apps'] as FormControl).value || [];
	}

	ngOnInit(): void {
		const formSub$ = this.form.valueChanges.subscribe(() => {
			this.totalSelectedApps.set(this.appsList.length);
		});

		this.subscriptions.push(formSub$);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	onSave(event: SubmitEvent) {
		this.form.markAsDirty();
		event.preventDefault();

		if (this.form.invalid) {
			return;
		}

		this.updateApps();
	}

	onSaveAndPush() {
		this.form.markAsDirty();

		if (this.form.invalid) {
			return;
		}

		const isSaveAndPush = true;
		this.updateApps(isSaveAndPush);
	}

	updateApps(pushChanges: boolean = false) {
		this.isLoading.set(true);

		const data = (this.appsList as AndroidAppConfig[]).map((app) => ({
			appId: app.appResponse?.id,
			packageName: app.appResponse?.packageName,
			status: Number(app.status),
			internetConfig: Number(app.internetConfig),
		}));

		const updateSub$ = this.policyDetailsService
			.updateApps(this.currentGroup().id, data, pushChanges)
			.pipe(
				finalize(() => this.isLoading.set(false)),
				catchError(() => {
					throw new Error(
						'An error occurred while trying to save your app list changes. Please try again later.',
					);
				}),
			)
			.subscribe(() => {
				this.notificationService.openSuccess({
					message: `App list restrictions updated ${pushChanges ? 'and pushed' : ''} successfully.`,
				});

				const sub$ = this.policyDetailsService.getGroup(this.currentGroup().id).subscribe();
				this.subscriptions.push(sub$);
			});

		this.subscriptions.push(updateSub$);
	}
}
