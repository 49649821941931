import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
	selector: 'csd-app-step',
	standalone: true,
	imports: [MatIconModule],
	templateUrl: './step.component.html',
	styleUrl: './step.component.scss',
})
export class StepComponent {
	icon = input.required<string>();
	text = input.required<string>();
	contentGrow = input<boolean>(false);
}
