import { Component, input, output } from '@angular/core';
import { MtxAlertModule, MtxAlertType } from '@ng-matero/extensions/alert';

@Component({
	selector: 'csd-app-banner',
	standalone: true,
	imports: [MtxAlertModule],
	templateUrl: './banner.component.html',
	styleUrl: './banner.component.scss',
})
export class BannerComponent {
	elevation = 0;

	type = input<MtxAlertType>('info');
	dismissible = input<boolean>(false);

	closed = output();

	onClosed(e: any) {
		this.closed.emit();
	}
}
