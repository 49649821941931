<div class="policy-restrictions">
	<div class="policy-restrictions__header">Restrict access to selected apps:</div>
	<div class="policy-restrictions__content">
		@for (item of restrictionsList; track item.settingId; let i = $index) {
			<csd-app-app-checkbox
				class="w-full"
				[text]="item.setting.name"
				[checked]="item.value"
				(change)="changeSelection($event, $index)"
			></csd-app-app-checkbox>
		}
	</div>
</div>
