import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { LocalStorageManagerService } from '@core/local-storage-manager/local-storage-manager.service';
import { triggerCollapse } from '@root/app/animations';

@Component({
	selector: 'csd-app-section',
	standalone: true,
	imports: [MatIconModule, MatRippleModule],
	templateUrl: './section.component.html',
	styleUrl: './section.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [triggerCollapse],
})
export class SectionComponent implements OnInit {
	#localStorage = inject(LocalStorageManagerService);

	header = input.required<string>();
	collapsible = input<boolean>(false);

	isCollapsed = signal<boolean>(true);

	ngOnInit(): void {
		const config = this.loadConfig();
		const status = config[`section-collapse-${this.header()}`];

		this.isCollapsed.set(status ?? true);
	}

	toggleCollapse() {
		this.saveConfig(!this.isCollapsed());
		this.isCollapsed.set(!this.isCollapsed());
	}

	saveConfig(status: boolean) {
		const configFormat = this.loadConfig();
		const newConfig = {
			...configFormat,
			[`section-collapse-${this.header()}`]: status,
		};

		this.#localStorage.write('config', JSON.stringify(newConfig));
	}

	loadConfig() {
		const config = this.#localStorage.read('config');
		return JSON.parse(config || '{}');
	}
}
