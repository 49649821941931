import { Routes } from '@angular/router';

import { LoginCallbackComponent } from './login-callback/login-callback.component';
import { LoginComponent } from './login/login.component';

export default <Routes>[
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: 'login-callback',
		component: LoginCallbackComponent,
	},
];
